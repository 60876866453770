<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <el-button size="small" type="primary" @click="showUserForm('addData')" v-show="allow('system.admin.store')"><i class="el-icon-plus"></i>
                                添加
                            </el-button>
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入昵称"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入手机号"
                                                v-model="filter.phone">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getAdminList"><i class="el-icon-search"></i></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="table-list">
                        <el-table
                                :data="tableData">
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="用户名"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="type"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="类别"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="用户昵称"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="email"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="邮箱"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="phone"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="手机"
                            >
                            </el-table-column>
                            <!--<el-table-column-->
                                    <!--prop="status"-->
                                    <!--width="100"-->
                                    <!--align="center"-->
                                    <!--label="状态"-->
                            <!--&gt;-->
                                <!--<template slot-scope="scope">-->
                                    <!--<el-switch-->
                                            <!--v-model="scope.row.status"-->
                                            <!--disabled-->
                                            <!--class="captcha-img"-->
                                            <!--:active-value="1"-->
                                            <!--:inactive-value="2"-->
                                            <!--@click.native="changeStatus(scope.row)">-->
                                    <!--</el-switch>-->
                                <!--</template>-->
                            <!--</el-table-column>-->
                            <el-table-column
                                    prop="role"
                                    min-width="15%"
                                    align="center"
                                    label="角色"
                            >
                                <template slot-scope="scope">
                                    <el-tag type="success" v-for="item in scope.row.roles" :key="item.value"
                                            size="small" style="margin: 0 3px;">
                                        <span >{{item.display_name}}</span>
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" width="140">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="showEditRoleForm(scope.row)" v-show="allow('system.admin.update')">编辑角色</el-button>
                                    <el-button type="text" title="编辑" v-show="allow('system.admin.update')" @click="showUserForm('addData');getAdminInfo(scope.row.id);"><i class="el-icon-edit"></i></el-button>
                                    <!--<el-button type="text" title="删除" @click="deleteItem"><i class="el-icon-delete"></i>-->
                                    <!--</el-button>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <!-- 添加用户 -->
        <el-dialog
                custom-class="dialog_tc"
                :title="title"
                :visible.sync="addUser"
                width="480px"
        >
            <el-form ref="addData" :model="addData" :rules="rules" label-width="80px" size="medium">
                <el-form-item label="用户名" prop="name">
                    <el-input
                            type="text"
                            placeholder="请输入用户名"
                            v-model="addData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input
                            type="text"
                            placeholder="请输入邮箱"
                            v-model="addData.email"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="phone">
                    <el-input
                            type="text"
                            placeholder="请输入手机号码"
                            v-model="addData.phone"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" :rules="addData.id?[{ required: false}]:rules.password">
                    <el-input
                            type="password"
                            placeholder="请输入密码"
                            show-password
                            v-model="addData.password"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用户类别" prop="type">
                <el-select v-model="addData.type" placeholder="请选择">
                    <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                            v-model="addData.status"
                            :active-value="1"
                            :inactive-value="2"
                    >
                    </el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addUser = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveAdminData('addData')"
        >保存</el-button
        >
      </span>
        </el-dialog>
        <!-- 编辑用户角色 -->
        <el-dialog
                custom-class="dialog_tc"
                title="编辑用户角色"
                :visible.sync="editRole"
                width="480px"
        >
            <el-form ref="editRoleData" :model="editRoleData" label-width="80px" size="medium">
                <el-checkbox-group v-model="editRoleData.roles">
                    <el-checkbox v-for="(role,index) in roleOptions" :label="role.id" :key="role.id" :value="role.id">{{role.display_name}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="editRole = false;">取 消</el-button>
        <el-button size="small" type="primary" @click="saveAdminRole('editRoleData')"
        >保存</el-button
        >
      </span>
        </el-dialog>
    </div>
</template>
<script>
    import Api from '@/api'
    import mixinPage from '@/mixins/page'
    // let id = '1000'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                title:'添加用户',
                currentPage: 1,
                pageSize: 15,
                total: 0,
                filter: {
                    keyWord: '',
                    phone: ''
                },
                tableData: [],
                addUser: false,
                addData: {
                    name: "",
                    email: "",
                    phone: "",
                    password: "",
                    status: "",
                    id:'',
                    type:''
                },
                editRole: false,
                roleOptions:[],
                editRoleData: {
                    roles: [],
                    model_id:''
                },
                typeOptions:[
                    {
                        value: 'company',
                        label: '子公司账户'
                    },
                    {
                        value: 'platform',
                        label: '平台账户'
                    },
                ],
                rules:{
                    name: [
                        {
                            required: true,
                            message: '请输入用户名',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    phone: [
                        {
                            required: true,
                            message: '请输入手机号',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    status: [
                        {
                            required: true,
                            message: '请设置用户状态',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    type: [
                        {
                            required: true,
                            message: '请选择用户类别',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    password:[
                        {
                            required: true,
                            message: '请设置用户密码',
                            trigger: ['change', 'blur'],
                        },
                    ]

                }
            }
        },
        mounted() {
            this.getAdminList()
        },
        created(){
            this.getRoleList()
        },
        methods: {
            //获取单条用户信息
            async getAdminInfo(id){
                this.title = '编辑用户'
                await Api.model.show('admin',id).then((res) => {
                    this.$nextTick(() => {
                        res.password = ''
                        this.addData = res
                    })
                })
            },
            //保存用户信息
            saveAdminData(form){
                let params = this.addData
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        if(this.addData.id){
                            await Api.model.update('admin',params, params.id)
                        }else{
                            await Api.model.store('admin',params)
                        }
                        this.success('成功')
                        this.addUser = false
                        this.resetForm(form)
                        this.getAdminList()
                    }
                });
            },
            //显示添加用户表单
            showUserForm(form){
                this.addUser = true
                this.resetForm(form)
            },
            //显示编辑角色弹框
            showEditRoleForm(item){
                this.editRole = true
                if(item.roles.length!=0){
                    item.roles.forEach(item=>{
                        this.editRoleData.roles.push(item.id)
                    })
                }
                this.editRoleData.model_id = item.id
            },
            //给用户分配角色
            async saveAdminRole(form){
                let params = this.editRoleData
                await Api.system.assignRole('admin',params).then(res=>{
                    this.getAdminList()
                    this.editRole = false
                    this.resetForm(form)
                })

            },
            //获取角色列表
            async getRoleList(){
                await Api.model.index('role',{}).then((res)=>{
                    this.roleOptions = res
                })
            },
            //获取用户列表
            async getAdminList(){
                let params = {
                    keyWord:this.filter.keyWord,
                    phone:this.filter.phone,
                    page:this.currentPage,
                    pageSize:this.pageSize
                }
                await Api.model.index('admin',params).then(res=>{
                    this.tableData = res.data
                    this.total = res.total
                })
            },
            deleteItem() {
                this.$confirm("确认删除此用户？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
            // 状态操作
            changeStatus(row) {
                this.$confirm(`确定${!row.status ? '开启该用户账号' : '停用该用户账号'}？`, "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true
                }).then(() => {
                    row.status = !row.status
                }).catch(() => {
                    this.$message.error('取消操作')
                })
            },
        }
    }
</script>
