<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <el-button size="small" type="primary" v-show="allow('system.role.store')" @click="showRoleForm('addData')"><i
                                    class="el-icon-plus"></i>
                                添加
                            </el-button>
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入关键字"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small"><i class="el-icon-search"></i></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="table-list">
                        <el-table
                                :data="tableData">
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="名称"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="display_name"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="显示名称"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="users"
                                    :show-overflow-tooltip="true"
                                    min-width="30%"
                                    label="用户列表"
                                    :formatter="Formatter"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="guard_name"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="Guard Name"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="created_at"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="创建时间"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="updated_at"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="更新时间"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column align="center" label="操作" width="140">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="open(scope.row.id)" v-show="allow('system.role.update')" >配置权限</el-button>
                                    <el-button type="text" title="编辑"
                                               v-show="allow('system.role.update')"
                                               @click="showRoleForm('addData');getRoleInfo(scope.row.id);"><i
                                            class="el-icon-edit"></i></el-button>
                                    <!--<el-button type="text" title="删除" @click="deleteItem"><i class="el-icon-delete"></i></el-button>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <!-- 添加角色 -->
        <el-dialog
                custom-class="dialog_tc"
                :title="title"
                :visible.sync="addRole"
                width="480px"
        >
            <el-form ref="addData" :rules="rules" :model="addData" label-width="80px" size="medium">
                <el-form-item label="名称" prop="name">
                    <el-input
                            type="text"
                            placeholder="请输入名称"
                            v-model="addData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="显示名称" prop="display_name">
                    <el-input
                            type="text"
                            placeholder="请输入显示名称"
                            v-model="addData.display_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Guard" prop="guard_name">
                    <el-select v-model="addData.guard_name" placeholder="请选择">
                        <el-option
                                v-for="item in guardOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addRole = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveRoleData('addData')">保存</el-button>
      </span>
        </el-dialog>

        <!-- 配置权限 -->
        <el-dialog
                custom-class="dialog_tc scrollDialog"
                title="配置权限"
                :visible.sync="setPermission"
                width="560px"
        >
            <el-scrollbar>
                <div class="permission-tree">
                    <el-tree
                            :data="permissionTree"
                            :props="defaultProps"
                            node-key="name"
                            show-checkbox
                            default-expand-all
                            :expand-on-click-node="false"
                            :check-on-click-node="true"
                            :check-strictly="true"
                            icon-class="el-icon-arrow-right"
                            ref="tree"
                    >
                    </el-tree>
                </div>
            </el-scrollbar>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="setPermission = false">取 消</el-button>
        <el-button size="small" type="primary" @click="setPermissionData()"
        >保存</el-button
        >
      </span>
        </el-dialog>
    </div>
</template>
<script>
    import $ from 'jquery'
    import Api from '@/api'
    import mixinPage from '@/mixins/page'
    // let id = '1000'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                title: '添加角色',
                filter: {
                    keywWord: '',

                },
                tableData: [],
                addRole: false,
                guardOptions: [{
                    value: 'api',
                    label: "后台守卫"
                }
                ],
                addData: {
                    display_name: '',
                    name: '',
                    guard_name: '',
                    id: ''
                },
                role_id: "",
                setPermission: false,
                permissionTree: [],
                defaultProps: {
                    children: 'children',
                    label: 'display_name'
                },
                rules: {
                    name: [
                        {
                            required: true,
                            message: '请输入名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    display_name: [
                        {
                            required: true,
                            message: '请输入显示名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    guard_name: [
                        {
                            required: true,
                            message: '请选择守卫',
                            trigger: ['change', 'blur']
                        }
                    ]
                }
            }
        },
        mounted() {
        },
        created() {
            //获取角色列表
            this.getRolesList();
            //获取权限树
            this.getPermissionTree()
        },
        methods: {

            //获取权限树
            async getPermissionTree() {
                await Api.model.index('permission', {}).then((res) => {
                    this.permissionTree = res
                })
            },

            ///获取角色详情
            async getRoleInfo(id) {
                this.title = '编辑角色'
                await Api.model.show('role', id).then((res) => {

                    this.$nextTick(() => {
                        this.addData = res
                        let result = res.permissions;
                        let arr = [];
                        for (let index = 0; index < result.length; index++) {
                            const element = result[index];
                            arr.push(element.name);
                        }
                        this.$refs.tree.setCheckedKeys(arr);
                    })
                })
            },
            //保存角色信息
            async saveRoleData(form) {
                let params = this.addData
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        if (this.addData.id) {
                            await Api.model.update('role', params, params.id)
                        } else {
                            await Api.model.store('role', params)
                        }
                        this.success('成功')
                        this.addRole = false
                        this.resetForm(form)
                        this.getRolesList()
                    }
                });
            },
            //显示弹窗
            showRoleForm(form) {
                this.addRole = true
                this.resetForm(form)
            },
            async getRolesList() {
                await Api.model.index('role').then(res => {
                    this.tableData = res
                })
            },
            Formatter(row) {
                var userList = [];
                for (var i = 0; i < row.users.length; i++) {
                    userList.push(row.users[i].name);
                }
                return userList.join("、");
            },
            open(id) {
                this.setPermission = true
                setTimeout(() => {
                    for (let item of  $(".is-leaf")) {
                        $(item).parent().parent().parent().addClass('isLastFather')
                    }

                }, 1000)
                this.role_id = id
                this.getRoleInfo(id)
            },
            //配置权限
            async setPermissionData() {
                let checkData = this.$refs.tree.getCheckedKeys();

                let data = {
                    role_id: this.role_id,
                    permissions: checkData,
                };
                await Api.system.setRolePermission('role', data).then(res => {
                    this.success('成功')
                    this.setPermission = false;
                })

            },
            deleteItem() {
                this.$confirm("确认删除此权限？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
        }
    }
</script>
