<template>
    <div>
        <el-drawer
                :visible.sync="subTaskDrawer_"
                class="drawer_tc"
                size="90%"
                direction="btt"
        >
            <div class="drawer-title" slot="title">
                <div class="title"></div>
                <el-button
                        size="small"
                        type="primary"
                        @click="showChildrenForm()"
                        v-if="createSubTask_"
                ><i class="el-icon-plus"></i> 创建子任务
                </el-button>
            </div>
            <div class="content_container">
                <el-scrollbar>
                    <div class="container">
                        <div class="table-list">
                            <el-table :data="subtaskTableData">
                                <el-table-column
                                        prop="id"
                                        :show-overflow-tooltip="true"
                                        width="55"
                                        align="center"
                                        label="ID"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="name"
                                        :show-overflow-tooltip="true"
                                        label="子任务名称"
                                        min-width="20%"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="serial_code"
                                        :show-overflow-tooltip="true"
                                        label="子任务编号"
                                        align="center"
                                        min-width="15%"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="type"
                                        :show-overflow-tooltip="true"
                                        label="任务类型"
                                        min-width="15%"
                                        align="center"
                                >
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.type=='video'">视频</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="taker.name"
                                        :show-overflow-tooltip="true"
                                        label="制作者"
                                        align="center"
                                        min-width="15%"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.taker?scope.row.taker.name:''}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="money"
                                        :show-overflow-tooltip="true"
                                        label="金额（￥）"
                                        align="center"
                                        min-width="15%"
                                ></el-table-column>
                                <el-table-column
                                        prop="created_at"
                                        :show-overflow-tooltip="true"
                                        label="分配时间"
                                        min-width="15%"
                                        align="center"
                                ></el-table-column>
                                <el-table-column
                                        prop="expired_at"
                                        :show-overflow-tooltip="true"
                                        label="截止时间"
                                        min-width="15%"
                                        align="center"
                                ></el-table-column>
                                <el-table-column
                                        prop="status"
                                        width="80"
                                        :show-overflow-tooltip="true"
                                        align="center"
                                        label="状态"
                                >
                                    <template slot-scope="scope">
                                        <el-tag
                                                size="small"
                                                type="success"
                                        >{{taskStatusDesc(scope.row.status)}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="finished_at"
                                        :show-overflow-tooltip="true"
                                        label="完成时间"
                                        min-width="15%"
                                        align="center"
                                ></el-table-column>
                                <!--<el-table-column-->
                                        <!--prop="agreement"-->
                                        <!--width="65"-->
                                        <!--:show-overflow-tooltip="true"-->
                                        <!--align="center"-->
                                        <!--label="合同"-->
                                <!--&gt;-->
                                    <!--<template slot-scope="scope">-->
                                        <!--<el-link type="primary" @click="scope.row.agreement.complate_file_path!=null?openWindowBlank(scope.row.agreement.complate_file_path):openWindowBlank(scope.row.agreement.file_path)"-->
                                                 <!--v-if="scope.row.agreement_id != null && scope.row.agreement!=null">-->
                                            <!--<i class="el-icon-document"></i>-->
                                        <!--</el-link>-->
                                        <!--<el-upload-->
                                                <!--v-else-->
                                                <!--class="uploadThumbImg"-->
                                                <!--:action="uploadURL"-->
                                                <!--:headers="uploadHeaders"-->
                                                <!--:on-success="handleUploadSuccess"-->
                                                <!--:data="{module:'task',model_id:scope.row.id}"-->
                                                <!--:limit="1">-->
                                            <!--<i class="el-icon-upload2"></i>-->
                                        <!--</el-upload>-->
                                    <!--</template>-->
                                <!--</el-table-column>-->
                                <el-table-column align="center" label="操作" width="120">
                                    <template slot-scope="scope">
                                        <el-button
                                                type="text"
                                                title="查看"
                                                @click="subTaskView = true;viewSubTaskData(scope.row)"
                                        >
                                            <i class="el-icon-view"></i>
                                        </el-button>
                                        <el-button type="text" title="编辑" @click="editSubTaskData(scope.row)">
                                            <i class="el-icon-edit"></i>
                                        </el-button>
                                        <el-button
                                                type="text"
                                                :title="[scope.row.status == 1 ? '启用' : '撤回']"
                                                @click="isOpen(scope.row)"
                                                v-if="!taker_id"
                                        >
                                            <i class="el-icon-open" v-if="scope.row.status!=1"></i>
                                            <i class="el-icon-turn-off" v-else></i>
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </el-drawer>

        <!-- 创建子任务 -->
        <el-dialog

                custom-class="dialog_tc"
                title="创建子任务"
                :visible.sync="addSubTask"
                width="1400px"
        >
            <el-form
                    ref="addSubTaskInfo"
                    :model="addsubTaskData"
                    label-width="100px"
                    size="medium"
                    :rules="rules"
            >
                <el-form-item label="子任务名称" prop="name">
                    <el-input
                            type="text"
                            placeholder="请输入子任务名称"
                            v-model="addsubTaskData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="子任务编号" prop="serial_code">
                    <el-input
                            type="text"
                            placeholder="请输入子任务编号"
                            v-model="addsubTaskData.serial_code"
                    ></el-input>
                </el-form-item>
                <el-form-item label="截止日期" prop="expired_at">
                    <el-date-picker
                            v-model="addsubTaskData.expired_at"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择截止日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="任务类型" prop="type">
                    <el-select
                            v-model="addsubTaskData.type"
                            placeholder="请选择"
                            filterable
                            clearable
                            defaul
                    >
                        <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="制作者" prop="taker_id">
                    <el-select v-model="addsubTaskData.taker_id" placeholder="请选择" filterable>
                        <el-option

                                v-for="item in takerOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="合同" prop="fileList">
                    <el-upload
                            class="uploadThumbImg"
                            :action="uploadURL"
                            :headers="uploadHeaders"
                            :limit="1"
                            :data="{module:'task'}"
                            :on-success="handleUploadAddSuccess"
                            :file-list="addsubTaskData.fileList">
                        <div class="flex">
                            <el-button size="medium" plain>点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="margin-left:8px;margin-top:0;">只支持上传pdf文件
                            </div>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="任务要求" prop="requirement">
                    <div class="flex" style="margin-bottom: 15px">
                        <el-button size="small" type="primary" @click="getRequirement">
                            <i class="el-icon-plus"></i> 添加
                        </el-button>
                    </div>
                    <div class="table-list">
                        <el-table
                                :data="addsubTaskData.requirement"
                                border
                                size="small"
                        >
                            <el-table-column
                                    type="index"
                                    width="50"
                                    label="序号"
                                    align="center">
                            </el-table-column>
                            <el-table-column
                                    prop="category"
                                    label="分类"
                                    min-width="15%"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    label="基础价格"
                                    align="center"
                                    min-width="10%"
                                    prop="basePrice">
                            </el-table-column>
                            <el-table-column
                                    prop="requireContent"
                                    label="特殊技术要求"
                                    min-width="20%">
                            </el-table-column>
                            <el-table-column
                                    prop="price"
                                    label="技术价格"
                                    align="center"
                                    min-width="10%"
                            ></el-table-column>
                            <el-table-column
                                    label="合计价格"
                                    align="center"
                                    min-width="10%">
                                <template slot-scope="scope">
                                    {{ parseFloat(scope.row.price) + parseFloat(scope.row.basePrice) }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="content"
                                    label="内容要求"
                                    min-width="20%">
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="80px">
                                <template slot-scope="scope">
                                    <el-button
                                            type="text"
                                            title="删除"
                                            @click="remove(scope.$index, scope.row)"
                                    ><i class="el-icon-delete"></i
                                    ></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
                <el-form-item label="质量规范要求" prop="content">
                    <Complex v-model="addsubTaskData.content"></Complex>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addSubTask = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveTaskData('addSubTaskInfo')"
        >保存</el-button
        >
      </span>
        </el-dialog>

        <!-- 任务要求 -->
        <el-dialog
                title="请选择任务要求"
                :visible.sync="requirementDialog"
                width="1200px">
            <div class="requirement-list">
                <el-table
                        :data="requirementList"
                        border
                        size="small"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column
                            type="selection"
                            width="55"
                            align="center"
                            :selectable="(value)=>{ return value.task_id?false:true }"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="category"
                            label="分类"
                            min-width="15%"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            label="基础价格"
                            align="center"
                            min-width="10%"
                            prop="basePrice">
                    </el-table-column>
                    <el-table-column
                            prop="requireContent"
                            label="特殊技术要求"
                            min-width="20%">
                    </el-table-column>
                    <el-table-column
                            prop="price"
                            label="技术价格"
                            align="center"
                            min-width="10%"
                    ></el-table-column>
                    <el-table-column
                            label="合计价格"
                            align="center"
                            min-width="10%">
                        <template slot-scope="scope">
                            {{ parseFloat(scope.row.price) + parseFloat(scope.row.basePrice) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="content"
                            label="内容要求"
                            min-width="20%">
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer flex is-between">
            <div class="left">
              已选择 <span style="color: red">{{multipleSelection.length}}</span> 条
            </div>
            <div class="right">
              <el-button size="small" @click="requirementDialog = false">取 消</el-button>
              <el-button size="small" type="primary" @click="addSelection">确 定</el-button>
            </div>
          </span>
        </el-dialog>

        <!-- 查看子任务 -->
        <el-drawer
                :visible.sync="subTaskView"
                class="drawer_tc"
                size="40%"
                direction="rtl"
        >
            <div class="drawer-title" slot="title">
                <div class="title"> {{subTaskViewData.name}}</div>
            </div>
            <div class="content_container">
                <el-scrollbar>
                    <div class="container">
                        <el-descriptions
                                class="margin-top"
                                :column="2"
                                size="medium"
                                border
                        >
                            <el-descriptions-item>
                                <template slot="label"> 任务名</template>
                                {{subTaskViewData.name}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 任务编号</template>
                                {{subTaskViewData.serial_code}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 任务类型</template>
                                <span v-if="subTaskViewData.type=='video'">视频</span>
                                <span v-else></span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 制作者</template>
                                {{subTaskViewData.taker?subTaskViewData.taker.name:''}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 身份证</template>
                                {{subTaskViewData.taker?subTaskViewData.taker.passport:''}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 手机号</template>
                                {{subTaskViewData.taker?subTaskViewData.taker.phone:''}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 分配时间</template>
                                {{subTaskViewData.created_at}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 截止时间</template>
                                {{subTaskViewData.expired_at}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 任务状态</template>
                                <el-tag size="small" type="success">{{taskStatusDesc(subTaskViewData.status)}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 完成时间</template>
                                {{subTaskViewData.finished_at}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 金额</template>
                                ￥{{subTaskViewData.money}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 合同</template>
                                <a href=""></a>
                                <el-link type="primary" v-if="subTaskViewData.agreement"
                                         @click="subTaskViewData.agreement.complate_file_path!=null?openWindowBlank(subTaskViewData.agreement.complate_file_path):openWindowBlank(subTaskViewData.agreement.file_path)">点击查看
                                </el-link>
                                <span type="primary" v-else href=""></span>
                            </el-descriptions-item>
                            <el-descriptions-item :labelStyle="{ 'vertical-align': 'top' }" :span=2>
                                <template slot="label"> 任务详情</template>
                                <span v-html="subTaskViewData.content"></span>
                            </el-descriptions-item>
                        </el-descriptions>

                        <h4 style="margin: 20px 0 10px"><strong>要求完成情况：</strong></h4>
                        <div class="table-list">
                            <el-table :data="taskChildrenInfo.requirement" border>
                                <el-table-column
                                        prop="category"
                                        :show-overflow-tooltip="true"
                                        label="任务分类"
                                        align="center"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.price_template.duration_code}}：{{scope.row.price_template.category_name}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="video"
                                        label="视频地址"
                                        min-width="60%"
                                >
                                    <template slot-scope="scope">
                                        <div v-for="item in scope.row.file" :key='item.id'>
                                            <!-- <el-link type="primary" :href="item.path" target="_blank"> -->
                                            <el-link type="primary" target="_blank" @click="videoDetailReview(item)">
                                                <i class="el-icon-video-camera"></i>
                                                <span>{{item.name}}</span>
                                            </el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="finishedDate"
                                        :show-overflow-tooltip="true"
                                        label="上传时间"
                                        align="center"
                                >
                                    <template slot-scope="scope">
                                        <div v-for="item in scope.row.file" :key='item.id'>
                                            <span>{{item.created_at}}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="fixed-bottom" style="text-align: center" v-if="taskChildrenInfo.disabled == 1">
                        <el-button type="warning" size="medium"
                                   @click="rejectTask(taskChildrenInfo,taskChildrenInfo.rejectStatus)">
                            <i class="el-icon-circle-check"></i> 驳回
                        </el-button>
                        <el-button type="primary" size="medium"
                                   @click="passTask(taskChildrenInfo,taskChildrenInfo.passStatus)">
                            <i class="el-icon-circle-check"></i> 通过
                        </el-button>
                    </div>
                </el-scrollbar>
            </div>
        </el-drawer>
        <!-- 视频弹框 -->
        <el-dialog
            :title="videoTitle"
            :visible.sync="videoDetail"
            width="800px"
            class="dialog_tc">
            <video-player  class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            ></video-player>
        </el-dialog>
    </div>
</template>

<script>
    import Editor from "../../components/tinymce-editor/tinymce-editor";
    import Complex from "../../components/tinymce-editor/tinymce-complex";
    import Api from '@/api'
    import mixinPage from '@/mixins/page'

    export default {
        components: {
            Editor,
            Complex,
        },
        mixins: [mixinPage],
        data() {
            return {
                taskStatusArr: [],
                key: 1,
                taskChildrenInfo: [],
                subtaskTableData: [],
                addSubTask: false,
                typeOptions: [
                    {
                        value: "video",
                        label: "视频",
                    },
                ],
                takerOptions: [],
                categoryOptions: [],
                addsubTaskData: {
                    expired_at: '',
                    name: "",
                    type: "",
                    taker_id: "",
                    project_id: this.project_id,
                    serial_code: '',
                    parent_id: this.id,
                    content: "",
                    money: 0,
                    requirementData: [],
                    requirement: [],
                    file_info: {},
                    fileList: [],
                    id: ''
                },

                subTaskView: false,
                subTaskViewData: {},
                requirementData2: [],
                requirementDialog: false,
                requirementList: [],
                multipleSelection: [],
                rules: {
                    name: [
                        {
                            required: true,
                            message: '请输入任务名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    expired_at: [
                        {
                            required: true,
                            message: '请选择任务截止日期',
                            trigger: ['change', 'blur']
                        }
                    ],
                    serial_code: [
                        {
                            required: true,
                            message: '请输入任务编码',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    type: [
                        {
                            required: true,
                            message: '请选择任务类型',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    taker_id: [
                        {
                            required: true,
                            message: '请选择任务制作者',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    requirement: [
                        {
                            validator: function (rule, value, callback) {
                                if (value.length == 0) {
                                    callback(new Error("请选择任务要求"))
                                    return false;
                                }
                                callback();
                            },
                        },
                    ]
                },
                videoTitle:"",
                videoDetail: false,
                playerOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: "" //url地址
                    }],
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true  //全屏按钮
                    }
                }
            };
        },
        props: {
            drawer: {
                type: Boolean,
                default: false,
            },
            create: {
                type: Boolean,
                default: true,
            },
            id: {
                type: Number,
            },
            project_id: {
                type: Number,
            },
            taker_id: {
                type: Number,
            }
        },
        watch: {},
        created() {
            if (this.project_id) {
                //获取制作者列表
                this.getTakerList()
                //获取当任务所需价格表
                this.getProjectPriceTemplatePackage()
            }
            //获取任务状态
            this.taskStatus().then(() => {
                //获取子任务数据
                this.getTaskChildrenList()
            })
        },

        methods: {
            //播放视频弹框
            videoDetailReview(videoUrl){
                if(videoUrl.type==null ||  videoUrl.type.indexOf('video') == -1){
                    window.open(videoUrl.path)
                    return false
                }else{
                    this.videoTitle = videoUrl.name
                    this.playerOptions.sources[0].src = videoUrl.path
                    this.videoDetail = true
                }
            },
            taskStatusDesc(status) {
                return this.taskStatusArr[status]
            },
            //获取任务状态
            async taskStatus() {
                await Api.model.custom('/api/common/taskStatus', 'get').then(res => {
                    this.taskStatusArr = res
                })
            },
            //审核通过
            passTask(item) {
                this.$confirm('确定审核通过么？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    Api.model.update('task', {status: item.passStatus}, item.id).then(res => {
                        this.getTaskChildrenList()
                        this.getTaskDetail(item)
                        this.$message({
                            type: 'success',
                            message: '成功!'
                        });
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //驳回
            rejectTask(item) {
                this.$prompt('请输入驳回原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'textArea'
                }).then(({value}) => {
                    Api.model.update('task', {status: item.rejectStatus, reason: value}, item.id).then(res => {
                        this.getTaskChildrenList()
                        this.getTaskDetail(item)
                        this.$message({
                            type: 'success',
                            message: '成功!'
                        });
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消'
                    });
                });
            },
            //选着之后回显
            addSelection() {
                this.addsubTaskData.requirement = this.multipleSelection
                this.multipleSelection.forEach(item => {
                    this.addsubTaskData.money += parseFloat(item.price)
                    this.addsubTaskData.money += parseFloat(item.basePrice)
                })
                this.requirementDialog = false
            },
            //选着
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            //获取任务要求
            getRequirement() {
                let params = {
                    parent_task_id: this.id
                }

                Api.task.taskRequirement(params).then(res => {
                    let requirementList = []
                    res.forEach(item => {
                        requirementList.push({
                            id: item.id,
                            task_id: item.task_id,
                            category: item.price_template.duration_code + '：' + item.price_template.category_name + item.price_template.duration,
                            requireContent: item.skill,
                            price: item.skill_price,
                            basePrice: item.price,
                            content: item.description
                        })
                    })
                    this.requirementList = requirementList
                    this.requirementDialog = true
                })

            },
            viewSubTaskData(item) {
                this.subTaskViewData = item
                this.getTaskDetail(item)
            },
            //编辑任务
            editSubTaskData(item) {
                if(item.status!=1 && item.status!=2 && item.status!=3){
                    this.warning('该任务正在进行中不可编辑！！！')
                    return false
                }
                this.addSubTask = true

                Api.model.show('task', item.id).then(res => {
                    this.addsubTaskData.expired_at = res.expired_at
                    this.addsubTaskData.name = res.name
                    this.addsubTaskData.type = res.type
                    this.addsubTaskData.taker_id = parseInt(res.taker_id)
                    this.addsubTaskData.serial_code = res.serial_code
                    this.addsubTaskData.content = res.content
                    this.addsubTaskData.money = res.money
                    this.addsubTaskData.id = res.id
                    if(res.agreement!=null && res.agreement!=''){
                        this.addsubTaskData.file_info = {
                            model_id: res.agreement.model_id,
                            module: 'task',
                            file_path: res.agreement.uri
                        }
                    }else{
                        this.addsubTaskData.file_info = {}
                    }

                    this.addsubTaskData.fileList = []
                    if(res.agreement!=null && res.agreement!=''){
                        this.addsubTaskData.fileList.push({
                            name: res.agreement.name,
                            path: res.agreement.uri
                        })
                    }

                    this.addsubTaskData.requirement = []
                    res.requirement.forEach(item => {
                        this.addsubTaskData.requirement.push({
                            id: item.id,
                            task_id: item.task_id,
                            category: item.price_template.duration_code + '：' + item.price_template.category_name + item.price_template.duration,
                            requireContent: item.skill,
                            price: item.skill_price,
                            basePrice: item.price,
                            content: item.description
                        })
                    })

                })

            },
            //获取子任务详情
            getTaskDetail(item) {
                Api.model.show('task', item.id).then(res => {
                    this.taskChildrenInfo = res
                })
            },
            //文件上传成功后操作
            async handleUploadSuccess(response) {
                if (response.code == 200) {
                    let params = {
                        model_id: response.data.model_id,
                        module: response.data.module,
                        file_path: response.data.uri
                    }
                    //修改
                    await Api.task.saveAgreement(params).then(() => {
                        this.getTaskChildrenList();
                    })
                }
            },
            //上传文件
            async handleUploadAddSuccess(response) {
                if (response.code == 200) {
                    this.addsubTaskData.file_info = {
                        model_id: response.data.model_id,
                        module: response.data.module,
                        file_path: response.data.uri
                    }
                }
            },

            //显示表单任务
            showChildrenForm() {
                this.addsubTaskData.name = ""
                this.addsubTaskData.type = ""
                this.addsubTaskData.taker_id = ""
                this.addsubTaskData.project_id = this.project_id
                this.addsubTaskData.serial_code = ''
                this.addsubTaskData.parent_id = this.id
                this.addsubTaskData.content = ""
                this.addsubTaskData.money = 0
                this.addSubTask = true
                this.addsubTaskData.file_info = {}
                this.addsubTaskData.fileList = []
                this.addsubTaskData.requirement = []
            },

            //保存子任务
            async saveTaskData(form) {
                let params = this.addsubTaskData
                if (params.id) {
                    this.$refs[form].validate(async (valid) => {
                        if (valid) {//验证成功
                            await Api.model.update('task', params, params.id).then(res => {
                                this.getTaskChildrenList()
                                this.addsubTaskData.requirementData = []
                                this.addSubTask = false
                            })
                        }

                    })
                } else {
                    this.$refs[form].validate(async (valid) => {
                        if (valid) {//验证成功
                            await Api.model.store('task', params).then(res => {
                                this.getTaskChildrenList()
                                this.addsubTaskData.requirementData = []
                                this.addSubTask = false
                            })
                        }

                    })

                }

            },
            //选着分类项设置价格
            changeCategory(a, b) {
                let c = this.categoryOptions.filter(item => {
                    return item.value == a
                })
                this.addsubTaskData.requirementData[b].price = c[0].price
            },
            //获取价格表
            async getProjectPriceTemplatePackage() {
                let params = {
                    project_id: this.project_id,
                    type: 1
                }
                await Api.model.index('projectPriceTemplate', params).then(res => {
                    let category = []
                    res.forEach(item => {
                        let temp = item.duration_code + '：' + item.category_name + ' ' + item.duration
                        category.push({
                            label: temp,
                            value: item.id,
                            price: item.price
                        })

                    })
                    this.categoryOptions = category
                })
            },
            //获取所有制作者
            async getTakerList() {
                let params = {
                    limit: 'all'
                }
                await Api.model.index('taker', params).then(res => {
                    let taker = []
                    res.forEach(item => {
                        let temp = item.type == 1 ? '(医生) ' : '(服务商) '
                        taker.push({
                            value: item.id,
                            label: item.name + temp + '单位(' + item.department + ') 手机号(' + item.phone + ')'
                        })
                    })
                    this.takerOptions = taker
                })
            },
            //获取子任务数据
            async getTaskChildrenList() {
                let params = {
                    parent_id: this.id,
                    limit: 'all',
                    taker_id: this.taker_id
                }
                await Api.model.index('task', params).then(res => {
                    this.subtaskTableData = res.data
                })
            },
            //
            computNum() {
                let totalPrice = 0;
                this.addsubTaskData.requirementData.forEach(item => {
                    totalPrice += parseFloat(item.price) * item.num
                })
                this.addsubTaskData.money = totalPrice
            },
            isOpen(row) {
                this.$confirm(
                    `确认${row.status != 1 ? "撤回" : "启用"}该任务？`,
                    "",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        center: true,
                    }
                ).then(async () => {
                    if (row.status == 1) { //启用任务信息
                        await Api.model.update('task', {status: 2}, row.id).then(res => {
                            this.getTaskChildrenList()
                        })
                    } else { //撤回任务
                        if (row.status == 2 || row.status == 3) { //已分配待领取 已拒绝可以撤回
                            await Api.model.update('task', {status: 1}, row.id).then(res => {
                                this.getTaskChildrenList()
                            })
                        } else {
                            this.warning('该任务正在进行中不可撤回')
                        }
                    }

                }).catch(() => {

                });
            },
            // 删除
            remove(index, row) {
                this.$confirm(`确定删除该行数据吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                }).then(() => {
                    this.addsubTaskData.requirement.splice(index, 1);
                });
            },
        },
        computed: {
            subTaskDrawer_: {
                get() {
                    return this.drawer;
                },
                set(v) {
                    this.$emit("changeDrawer", v);
                },
            },
            createSubTask_: {
                get() {
                    console.log(this.create);
                    return this.create;
                },
            },
        },
    };
</script>
