<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;padding-bottom:0;">

                    <h1 style="margin-top: 12px;font-weight: 600;font-size: 18px"  v-show="project_id">{{projectData.name}}</h1>
                    <hr style="margin-top: 12px;background-color:#EBEEF5; height:1px; border:none;" v-show="project_id">
                    <div class="header_container is-sticky-top">

                        <div class="left">
                            <el-button size="small" type="primary" @click="showTaskForm('taskData')" v-show="allow('task.store')"><i class="el-icon-plus"></i>
                                新建任务
                            </el-button>
                        </div>
                        <!--查询-->
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-select v-model="filter.project_id" clearable filterable
                                                   placeholder="请选择项目名称">
                                            <el-option
                                                    v-for="item in projectOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <!--<el-form-item>-->
                                        <!--<el-select v-model="filter.projectLeaderValue" clearable filterable-->
                                                   <!--placeholder="请选择负责人">-->
                                            <!--<el-option-->
                                                    <!--v-for="item in projectLeaderOptions"-->
                                                    <!--:key="item.value"-->
                                                    <!--:label="item.label"-->
                                                    <!--:value="item.value">-->
                                            <!--</el-option>-->
                                        <!--</el-select>-->

                                    <!--</el-form-item>-->
                                    <!--<el-form-item>-->
                                        <!--<el-select v-model="filter.statusValue" clearable filterable-->
                                                   <!--placeholder="请选择状态">-->
                                            <!--<el-option-->
                                                    <!--v-for="item in filter.statusOptions"-->
                                                    <!--:key="item.value"-->
                                                    <!--:label="item.label"-->
                                                    <!--:value="item.value">-->
                                            <!--</el-option>-->
                                        <!--</el-select>-->
                                    <!--</el-form-item>-->
                                    <el-form-item>
                                        <el-date-picker
                                                v-model="filter.timerange"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="daterange"
                                                align="right"
                                                unlink-panels
                                                range-separator="-"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                :picker-options="filter.pickerOptions"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入关键字"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getTaskList"><i class="el-icon-search"></i></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <!--列表-->
                    <div class="table-list">
                        <el-table
                                :data="tableData">
                            <el-table-column
                                    prop="id"
                                    :show-overflow-tooltip="true"
                                    width="55"
                                    align="center"
                                    label="ID"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    label="主任务名称"
                                    min-width="20%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="serial_code"
                                    :show-overflow-tooltip="true"
                                    label="任务编号"
                                    min-width="15%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="project.name"
                                    :show-overflow-tooltip="true"
                                    label="项目名称"
                                    min-width="20%"
                            ></el-table-column>
                            <el-table-column
                                    prop="children_count"
                                    :show-overflow-tooltip="true"
                                    width="80"
                                    align="center"
                                    label="子任务"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="toSubTask(scope.row)">{{scope.row.children_count}}</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="money"
                                    :show-overflow-tooltip="true"
                                    label="金额（￥）"
                                    align="center"
                                    min-width="15%"
                            ></el-table-column>
                            <el-table-column
                                    prop="created_at"
                                    :show-overflow-tooltip="true"
                                    label="创建时间"
                                    min-width="15%"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    prop="status"
                                    width="80"
                                    :show-overflow-tooltip="true"
                                    align="center"
                                    label="状态"
                            >
                                <template slot-scope="scope">
                                    <el-tag size="small" type="warning">{{taskStatusDesc(scope.row.status)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" width="120">
                                <template slot-scope="scope">
                                    <el-button type="text" title="查看" @click="showTaskView(scope.row.id)"><i
                                            class="el-icon-view"></i></el-button>
                                    <el-button type="text" title="编辑" v-show="allow('task.update')" @click="editTaskForm('taskData');getTaskInfo(scope.row.id);"><i
                                            class="el-icon-edit"></i></el-button>
                                    <!--<el-button type="text"-->
                                               <!--v-show="allow('task.update')"-->
                                               <!--:title="[scope.row.isOpen? '启用' : '暂停']"-->
                                               <!--@click="isOpen(scope.row)"-->
                                    <!--&gt;-->
                                        <!--<i class="el-icon-open" v-if="scope.row.isOpen"></i>-->
                                        <!--<i class="el-icon-turn-off" v-else></i>-->
                                    <!--</el-button>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <div class="footer_container is-sticky-bottom">
                        <el-pagination
                                :current-page="currentPage"
                                :pager-count="5"
                                :page-size="pageSize"
                                layout="total, prev, pager, next"
                                :total="total"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>

        <!-- 新建任务 -->
        <el-dialog
                custom-class="dialog_tc"
                :title="title"
                :visible.sync="addTask"
                width="1400px"
                :key="key"
        >
            <el-form ref="taskData" :rules="rules" :model="addData" label-width="80px" size="medium">
                <el-form-item label="任务名称" prop="name">
                    <el-input
                            type="text"
                            placeholder="请输入任务名称"
                            v-model="addData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="任务编码" prop="serial_code">
                    <el-input
                            type="text"
                            :disabled="addData.disabled"
                            placeholder="请输入任务编码"
                            v-model="addData.serial_code"
                            disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="子公司" prop="company_id">
                    <el-select v-model="addData.company_id" placeholder="请选择所属子公司" filterable clearable :disabled="addData.disabled">
                        <el-option
                                v-for="item in parkOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="任务管理员" prop="admin_id">
                    <el-select v-model="addData.admin_id" placeholder="请选择所属子公司" filterable clearable :disabled="addData.disabled">
                        <el-option
                                v-for="item in adminUser"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称" prop="project_id" >
                    <el-select v-model="addData.project_id" placeholder="请选择" filterable clearable @change="changeProject"  :disabled="addData.disabled">
                        <el-option
                                v-for="item in projectOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="金额" prop="money" >
                    <el-input-number v-model="addData.money" :step='1000' :precision='2' :disabled="addData.disabled" controls-position="right"
                                     :min="0"></el-input-number>
                </el-form-item>

                <el-form-item label="任务要求" prop="requirementData">
                    <!-- <div class="flex">
                        <span class="red_color">总价：￥{{addData.money}}</span>
                    </div> -->
                    <div class="table-list">
                        <el-table
                                :data="addData.requirementData"
                                border
                                size="small"
                        >
                            <el-table-column
                                type="index"
                                width="50"
                                label="序号"
                                align="center">
                            </el-table-column>
                            <el-table-column
                                prop="category"
                                label="分类"
                                min-width="20%"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                            v-model="scope.row.category"
                                            placeholder="请选择"
                                            @change="changeCategory($event,scope.$index)"
                                            :disabled="checkDisable(scope.row)"
                                            clearable>
                                        <el-option
                                                v-for="item in categoryOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="基础价格" align="center" min-width="10%">
                                <template slot-scope="scope">
                                    {{ parseFloat(scope.row.price) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="特殊技术要求" align="center" min-width="20%">
                                <template slot-scope="scope">
                                    <el-input
                                            type="textarea"
                                            v-model="scope.row.skill"
                                            size="mini"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="price"
                                    label="技术价格"
                                    align="center"
                                     min-width="15%"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                            :disabled="checkDisable(scope.row)"
                                            v-model="scope.row.skill_price"
                                            :min="0"
                                            :step='1000'
                                            controls-position="right"
                                    ></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="合计价格" align="center" min-width="10%">
                                <template slot-scope="scope">
                                    {{ parseFloat(scope.row.price) + parseFloat(scope.row.skill_price) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="内容要求" align="center" min-width="20%">
                                <template slot-scope="scope">
                                    <el-input
                                            type="textarea"
                                            v-model="scope.row.description"
                                            size="mini"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="80px" >
                                <template slot-scope="scope">
                                    <el-button
                                            type="text"
                                            title="添加"
                                            @click="add(scope.$index, scope.row)"
                                    ><i class="el-icon-circle-plus-outline"></i
                                    ></el-button>
                                    <el-button
                                            type="text"
                                            title="删除"
                                            v-if="!checkDisable(scope.row)"
                                            @click="remove(scope.$index, scope.row)"
                                    ><i class="el-icon-delete"></i
                                    ></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
                <el-form-item label="主题及内容要求" prop="content">
                    <el-input type="textarea" :rows="6" v-model="addData.content"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addTask = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveTaskData('taskData')">保存</el-button>
      </span>
        </el-dialog>

        <!-- 任务详情 -->
        <el-drawer
                :visible.sync="taskView"
                class="drawer_tc"
                size="40%"
                direction="rtl">
            <div class="drawer-title" slot="title">
                <div class="title">
                    {{viewData.name}}
                </div>
            </div>
            <div class="content_container">
                <el-scrollbar>
                    <div class="container">
                        <el-descriptions class="margin-top" :column="2" size="medium" border>
                            <el-descriptions-item>
                                <template slot="label">
                                    任务编号
                                </template>
                                {{viewData.serial_code}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    所属子公司
                                </template>
                                {{viewData.company?viewData.company.name:''}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    项目名称
                                </template>
                                {{viewData.project!=null?viewData.project.name:''}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    子任务数
                                </template>
                                <el-link type="primary" @click="toSubTask(viewData)">{{viewData.children_count}}</el-link>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    总金额
                                </template>
                                {{'￥'+viewData.money}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    创建时间
                                </template>
                                {{viewData.created_at}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    任务状态
                                </template>
                                <el-tag size="small" type="success">{{taskStatusDesc(viewData.status)}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    任务进度
                                </template>
                                <el-progress
                                        :stroke-width="8"
                                        :percentage="viewData.final_children_count?Math.floor((viewData.final_children_count/viewData.children_count)*100):0"
                                        :color="customColorMethod"></el-progress>
                            </el-descriptions-item>
                            <el-descriptions-item :labelStyle="{'vertical-align': 'top'}">
                                <template slot="label">
                                    任务详情
                                </template>
                                {{viewData.content}}
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-scrollbar>
            </div>
        </el-drawer>

        <!-- 子任务列表 -->
        <subTask :drawer="subTaskDrawer" v-if="showSubTask" :create="createSubTask" :project_id="project_id" @changeDrawer="changeDrawer" :id="task_id"></subTask>

    </div>
</template>
<script>
    import subTask from "../../components/task/subTask";
    import Api from '@/api'
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        components: {
            subTask,
        },
        data() {
            return {
                taskStatusArr:[],
                adminUser:[],
                key:0,
                showSubTask:false,
                task_id:0,
                projectData:{name:''},
                project_id:'',
                title: '新建项目',
                currentPage: 1,
                pageSize: 15,
                total: 0,
                projectOptions:[],
                projectLeaderOptions: [],
                filter: {
                    keyWord: '',
                    project_id: "",
                    projectLeaderValue: "",
                    statusValue: "",
                    statusOptions: [{
                        value: "0",
                        label: "待分配"
                    }, {
                        value: "1",
                        label: "正常"
                    },{
                        value: "2",
                        label: "已分配"
                    },{
                        value: "3",
                        label: "暂停"
                    },{
                        value: "4",
                        label: "已提交"
                    },{
                        value: "5",
                        label: "进行中"
                    }, {
                        value: "6",
                        label: "已更新"
                    },{
                        value: "7",
                        label: "已完成"
                    }],
                    timerange: ["", ""],
                    pickerOptions: {
                        shortcuts: [
                            {
                                text: "最近一周",
                                onClick(picker) {
                                    const end = new Date();
                                    const start = new Date();
                                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                    picker.$emit("pick", [start, end]);
                                },
                            },
                            {
                                text: "最近一个月",
                                onClick(picker) {
                                    const end = new Date();
                                    const start = new Date();
                                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                    picker.$emit("pick", [start, end]);
                                },
                            },
                            {
                                text: "最近三个月",
                                onClick(picker) {
                                    const end = new Date();
                                    const start = new Date();
                                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                    picker.$emit("pick", [start, end]);
                                },
                            },
                        ],
                    },
                },
                tableData: [],
                addTask: false,
                viewData:{},
                categoryOptions: [],
                addData: {
                    id:'',
                    name: '',
                    project_id: '',
                    serial_code:'',
                    money: '',
                    agreement_id:'',
                    content:'',
                    requirementData: [],
                    company_id: '',
                    disabled:false,
                    admin_id:'',
                },
                parkOptions: [],
                taskView: false,
                subTaskDrawer: false,
                createSubTask: true,
                rules:{
                    name: [
                        {
                            required: true,
                            message: '请输入任务名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    company_id:[
                        {
                            required: true,
                            message: '请选择子公司',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    admin_id:[
                        {
                            required: true,
                            message: '请选择任务管理员',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    serial_code: [
                        {
                            required: true,
                            message: '请输入任务编码',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    project_id: [
                        {
                            required: true,
                            message: '请选着项目名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    money: [
                        {
                            required: true,
                            message: '请输入金额',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    requirementData:[
                        {
                            validator: function(rule, value, callback){
                                value.forEach(item=>{
                                    if(item.category == ''){
                                        callback(new Error('请填写任务要求'));
                                        return false;
                                    }else{
                                        callback();

                                    }
                                })
                            },

                        },
                    ]
                }
            }
        },
        //监听器
        watch: {
            $route: {
                handler: function(val, oldVal){
                    this.project_id = val.query.project_id==undefined?'':val.query.project_id
                    this.getTaskList()
                }
            }
        },
        mounted() {
            //获取任务信息
            this.getTaskList()
            //获取子公司
            this.getCompanyList()
            //获取任务管理员
            this.getAdminList()
        },
        created(){
            this.project_id = this.$route.query.project_id
            this.addData.project_id = this.$route.query.project_id?parseInt(this.$route.query.project_id):''
            this.filter.customer_id = this.$route.query.project_id?parseInt(this.$route.query.project_id):''
            //获取负责人信息
            this.getUserList().then(res=>{
                this.projectLeaderOptions = res
            })
            //获取任务状态
            this.taskStatus().then(()=>{
                //获取项目列表
                this.getProjectList().then(res=>{
                    this.projectOptions = res
                })
            })

        },
        methods: {
            checkDisable(row){
                if(row.bind_task!=null){
                    if(row.bind_task.status == 1){
                        return false
                    }else{
                        return true
                    }
                }else{
                    return false
                }
            },
            taskStatusDesc(status){
                return this.taskStatusArr[status]
            },
            //获取任务状态
            async taskStatus(){
                await Api.model.custom('/api/common/taskStatus','get').then(res=>{
                    this.taskStatusArr = res
                })
            },
            //获取任务管理员
            async getAdminList(){
                let params = {
                    type:'company'
                }
                await Api.model.index('admin',params).then(res=>{
                    let adminUser = []
                    res.forEach(item=>{
                        adminUser.push({
                            label:item.name,
                            value:item.id
                        })
                    })
                    this.adminUser = adminUser
                })
            },
            //获取子公司
            async getCompanyList() {
                await Api.company.index().then((res)=>{
                    let parkOptions = []
                    res.data.forEach(item=>{
                        parkOptions.push({
                            value:item.id,
                            label:item.name,
                        })
                    })
                    this.parkOptions = parkOptions

                })
            },
            //选着分类项设置价格
            changeCategory(a,b){
                let c = this.categoryOptions.filter(item=>{
                    return item.value == a
                })
                this.addData.requirementData[b].price = c[0].price
            },
            //获取价格表
            async getProjectPriceTemplatePackage(project_id){
                let params = {
                    project_id:project_id,
                    type:1
                }
                await Api.model.index('projectPriceTemplate',params).then(res=>{
                    let category = []
                    res.forEach(item=>{
                        let temp = item.duration_code+'：'+item.category_name+' '+item.duration
                        category.push({
                            label:temp,
                            value:item.id,
                            price:item.price
                        })

                    })
                    this.categoryOptions = category

                    if(this.addData.id == ''){
                        this.addData.requirementData = [{
                            category: '',
                            skill_price: 0,
                            price:0,
                            skill:'',
                        }]
                    }

                })
            },
            //查看详情
            showTaskView(id){
                this.taskView = true
                this.getTaskInfo(id)
            },
            //获取单条任务详情
            async getTaskInfo(id) {

                this.title = '编辑任务'
                await Api.model.show('task',id).then((res) => {
                    this.getProjectPriceTemplatePackage(res.project_id)
                    res.disabled = true
                    this.viewData = res
                    this.$nextTick(() => {

                        this.addData.requirementData = []
                        Object.keys(this.addData).forEach(item=>{
                            if(res[item]!=undefined){ //判断返回数据中是否存在该对象
                                this.addData[item] = res[item] //赋值给对象
                            }
                        })
                        res.requirement_data.forEach(item=>{
                            this.addData.requirementData.push({
                                id:item.id,
                                disabled:true,
                                category: item.project_price_template_id,
                                skill_price: item.skill_price,
                                price:item.price,
                                skill:item.skill,
                                description:item.description,
                                bind_task:item.bind_task
                            })
                        })
                    })

                })
            },
            //保存信息
            saveTaskData(form){
                let params = this.addData
                //判断任务要求价格是否大于总价格
                let money = params.money
                let requirementData = params.requirementData
                let tempPrice = 0;
                requirementData.forEach(item=>{
                    tempPrice+=parseFloat(item.price)+parseFloat(item.skill_price)
                })
                if(money<tempPrice){
                    this.warning("子任务总价不能大于主任务总价！！！")
                    return false
                }
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        if(this.addData.id){
                            await Api.model.update('task',params, params.id)
                        }else{
                            await Api.model.store('task',params)
                        }
                        this.success('成功')
                        this.addTask = false
                        this.resetForm(form)
                        this.getTaskList()
                    }
                });
            },

            //显示
            showTaskForm(form) {
                this.addData.id=''
                this.addData.disabled=false
                this.addData.requirementData=[]
                this.addTask = true
                this.resetForm(form)
                if(this.project_id){
                    this.getProjectInfo(this.project_id)
                    this.getProjectPriceTemplatePackage(this.project_id)
                }
            },
            editTaskForm(){
                this.addTask = true
            },
            changeProject(id){
                this.getProjectPriceTemplatePackage(id)
                this.getProjectInfo(id)
            },
            //获取单条项目详情
            async getProjectInfo(id) {

                await Api.project.show(id).then((res) => {
                    let nowDate = new Date()
                    let date = {
                        year: nowDate.getFullYear(),
                        month: nowDate.getMonth() + 1,
                    }
                    this.addData.serial_code = 'JK-RW-'+res.customer.code+'-'+date.year + date.month
                    console.log( this.addData.serial_code)
                })
            },
            //获取任务信息
            async getTaskList(){
                let params = {
                    page:this.currentPage,
                    pageSize:this.pageSize,
                    project_id:this.project_id?this.project_id:this.filter.project_id,
                    timerange:this.filter.timerange,
                    keyWord:this.filter.keyWord,
                    parent_id:0,
                    status:this.filter.statusValue,
                }
                await Api.model.index('task',params).then(res=>{
                    this.tableData = res.data
                    this.total = res.total
                    this.projectData = res.project
                })
            },
            toSubTask(item) {
                this.showSubTask = false
                this.$nextTick(() => {
                    this.showSubTask = true
                    this.task_id = item.id
                    this.subTaskDrawer = true;
                    this.project_id = item.project_id
                })

            },
            changeDrawer(v) {
                this.showSubTask = false
                this.subTaskDrawer = v;
            },
            customColorMethod(percentage) {
                if (percentage < 30) {
                    return '#909399';
                } else if (percentage < 70) {
                    return '#e6a23c';
                } else {
                    return '#67c23a';
                }
            },
            isOpen(row) {
                this.$confirm(`确认${row.isOpen === true ? '暂停' : '启用'}该任务？`, "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                        row.isOpen = !row.isOpen;
                    })
                    .catch(() => {
                    });
            },
            deleteItem() {
                this.$confirm("确认删除此条目？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                .then(() => {
                })
                .catch(() => {
                });
            },

            // 添加
            add(index, row) {
                let newList = {
                    category: '',
                    skill: '',
                    skill_price: '',
                    price: 0,
                    description: ''
                }
                this.addData.requirementData.splice(index + 1, 0, newList)
                console.log(this.addData.requirementData)
            },
            // 删除
            remove(index, row) {

                if (this.addData.requirementData.length > 1) {
                    let meg = ''
                    if(row.bind_task!=null){
                        if(row.bind_task.status == 1){
                            meg = '改要求已绑定子任务'+row.bind_task.name+',确认删除吗'
                        }else{

                        }
                    }else{
                        meg = '确定删除该行数据吗？'
                    }
                    this.$confirm(meg, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'error',
                    }).then(() => {
                        this.addData.requirementData.splice(index, 1);
                    });
                } else {
                    this.$message({
                        message: '再删就没有了哦！',
                        type: 'warning'
                    });
                }
            },
        }
    }
</script>
