<template>
    <div class="content_container no-bg">
        <el-scrollbar>
            <div class="scroll-container">
                <section>
                    <el-row :gutter="10" class="keywords keyword-block">
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">{{total.company}}</div>
                                    <div class="text">园区</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">{{total.customer}}</div>
                                    <div class="text">企业</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">{{total.project}}</div>
                                    <div class="text">项目</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">{{total.task}}</div>
                                    <div class="text">任务</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">20</div>
                                    <div class="text">视频</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">{{total.taker_doctor}}</div>
                                    <div class="text">医生</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">{{total.taker_server}}</div>
                                    <div class="text">服务商</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">
                                        <span>****</span>
                                        <el-tooltip effect="dark" content="仅限高级管理员查看" placement="top">
                                            <i class="el-icon-lock"></i>
                                        </el-tooltip>
                                    </div>
                                    <div class="text">已支付</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">
                                        <span>****</span>
                                        <el-tooltip effect="dark" content="仅限高级管理员查看" placement="top">
                                            <i class="el-icon-lock"></i>
                                        </el-tooltip>
                                    </div>
                                    <div class="text">待支付</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">
                                        <span>****</span>
                                        <el-tooltip effect="dark" content="仅限高级管理员查看" placement="top">
                                            <i class="el-icon-lock"></i>
                                        </el-tooltip>
                                    </div>
                                    <div class="text">资金池</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="keyword-item">
                            <div class="flex">
                                <div class="left">
                                    <div class="num">
                                        <span>****</span>
                                        <el-tooltip effect="dark" content="仅限高级管理员查看" placement="top">
                                            <i class="el-icon-lock"></i>
                                        </el-tooltip>
                                    </div>
                                    <div class="text">总流水</div>
                                </div>
                                <div class="icon">
                                    <i class="el-icon-news"></i>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </section>
                <section>
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>业务动态</span>
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-radio-group v-model="filter.radio">
                                            <el-radio-button label="最近一周"></el-radio-button>
                                            <el-radio-button label="最近一个月"></el-radio-button>
                                            <el-radio-button label="最近三个月"></el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-date-picker
                                                v-model="filter.timerange"
                                                type="daterange"
                                                align="right"
                                                unlink-panels
                                                range-separator="-"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                :picker-options="filter.pickerOptions"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div
                                class="chart"
                                id="analysis_line"
                                style="width: 100%; height: 400px"
                        ></div>
                    </el-card>
                </section>
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
    import * as echarts from 'echarts';
    import Api from '@/api'

    export default {
        data() {
            return {
                total:{

                },
                filter: {
                    radio: '最近一周',
                    timerange: ["2020-10-21", "2021-01-19"],
                    pickerOptions: {
                        shortcuts: [
                            {
                                text: "最近一周",
                                onClick(picker) {
                                    const end = new Date();
                                    const start = new Date();
                                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                    picker.$emit("pick", [start, end]);
                                },
                            },
                            {
                                text: "最近一个月",
                                onClick(picker) {
                                    const end = new Date();
                                    const start = new Date();
                                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                    picker.$emit("pick", [start, end]);
                                },
                            },
                            {
                                text: "最近三个月",
                                onClick(picker) {
                                    const end = new Date();
                                    const start = new Date();
                                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                    picker.$emit("pick", [start, end]);
                                },
                            },
                        ],
                    },
                },
            }
        },
        mounted() {
            this.drawLine();

        },
        created(){
            this.getTotal();
        },
        methods: {
            async getTotal(){
                await Api.statistics.total().then(res=>{
                    this.total = res
                })
            },
            drawLine() {
                this.analysisLine = echarts.init(
                    document.getElementById("analysis_line")
                );
                // 绘制图表
                this.analysisLine.setOption({
                    title: false,
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#FF806A",
                                width: "0.5",
                                snap: true,
                            },
                        },
                    },
                    legend: {
                        data: ["园区一", "园区二", "园区三"],
                        textStyle: {
                            fontSize: 12,
                        },
                    },
                    dataZoom: [
                        {
                            type: "inside",
                            minValueSpan: 4,
                            start: 0,
                            end: 50,
                            zoomLock: true,
                        },
                        {
                            type: "slider",
                            height: 16,
                            bottom: 10,
                        },
                    ],
                    grid: {
                        top: "60px",
                        left: "0%",
                        right: "20px",
                        bottom: "36px",
                        containLabel: true,
                    },
                    xAxis: {
                        data: [
                            "2021/1/11",
                            "2021/1/12",
                            "2021/1/13",
                            "2021/1/14",
                            "2021/1/15",
                            "2021/1/16",
                            "2021/1/17",
                            "2021/1/11",
                            "2021/1/12",
                            "2021/1/13",
                            "2021/1/14",
                            "2021/1/15",
                            "2021/1/16",
                            "2021/1/17",
                        ],
                        boundaryGap: false,
                    },
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: 500,
                            interval: 100,
                            axisLabel: {
                                formatter: "{value}万",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "园区一",
                            type: "line",
                            areaStyle: {},
                            lineStyle: {
                                width: 1,
                            },
                            smooth: true,
                            data: [
                                "160",
                                "136",
                                "486",
                                "450",
                                "276",
                                "376",
                                "209",
                                "111",
                                "398",
                                "109",
                                "265",
                                "490",
                                "500",
                                "320",
                            ],
                        },
                        {
                            name: "园区二",
                            type: "line",
                            areaStyle: {},
                            lineStyle: {
                                width: 1,
                            },
                            smooth: true,
                            data: [
                                "186",
                                "165",
                                "276",
                                "476",
                                "298",
                                "464",
                                "365",
                                "286",
                                "465",
                                "376",
                                "176",
                                "98",
                                "276",
                                "365",
                            ],
                        },
                        {
                            name: "园区三",
                            type: "line",
                            areaStyle: {},
                            lineStyle: {
                                width: 1,
                            },
                            smooth: true,
                            data: [
                                "196",
                                "267",
                                "382",
                                "298",
                                "109",
                                "298",
                                "387",
                                "402",
                                "278",
                                "398",
                                "299",
                                "178",
                                "365",
                                "420",
                            ],
                        },
                    ],
                });
                let self = this;
                window.addEventListener("resize", () => {
                    self.analysisLine.resize();
                });
            }
        }
    }
</script>
