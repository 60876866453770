<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <el-button size="small" type="primary" @click="showPermissionForm('addData')"  v-show="allow('system.permission.store')"><i
                                    class="el-icon-plus"></i> 添加
                            </el-button>
                        </div>
                    </div>
                    <div class="table-list">
                        <el-table
                                :data="tableData"
                                row-key="id"
                                default-expand-all
                                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                            <el-table-column
                                    prop="display_name"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="显示名称"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="权限名"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="route"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="路由"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="created_at"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="创建时间"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="updated_at"
                                    :show-overflow-tooltip="true"
                                    min-width="15%"
                                    label="更新时间"
                                    align="center"
                            >
                            </el-table-column>
                            <el-table-column align="center" label="操作" width="140">
                                <template slot-scope="scope">
                                    <el-button type="text" v-show="allow('system.permission.store')" title="编辑" @click="showPermissionForm('addData');getPermissionInfo(scope.row.id);"><i class="el-icon-edit"></i></el-button>
                                    <!--<el-button type="text" title="删除" @click="deleteItem"><i class="el-icon-delete"></i>-->
                                    <!--</el-button>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <!-- 添加权限 -->
        <el-dialog
                custom-class="dialog_tc"
                :title="title"
                :visible.sync="addPermission"
                width="480px"
        >
            <el-form ref="addData" :model="addData" :rules="rules" label-width="80px" size="medium">
                <el-form-item label="父级权限" prop="parent_id">
                    <el-cascader
                            v-model="addData.parent_id"
                            :options="permissionOptions"
                            clearable
                            filterable
                            multiple
                            :props="{ expandTrigger: 'hover',checkStrictly: true ,multiple: false,label: 'display_name',value: 'id'}"
                            placeholder="请选择父级权限"></el-cascader>
                </el-form-item>
                <el-form-item label="显示名称" prop="display_name">
                    <el-input
                            type="text"
                            placeholder="如：系统管理"
                            v-model="addData.display_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="权限标识" prop="name" >
                    <el-input
                            type="text"
                            placeholder="如：system.index"
                            v-model="addData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="路由" prop="route">
                    <el-input
                            type="text"
                            placeholder="如：admin.member"
                            v-model="addData.route"
                    ></el-input>
                </el-form-item>
                <el-form-item label="守卫" prop="guard_name">
                    <el-select v-model="addData.guard_name" clearable filterable placeholder="请选择守卫">
                        <el-option
                                v-for="item in guardOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>

                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addPermission = false">取 消</el-button>
        <el-button size="small" type="primary" @click="savePermissionData('addData')">保存</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<script>
    // let id = '1000'
    import Api from '@/api'
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                title:'添加权限',
                permissionOptions:[],
                guardOptions:[
                    {
                        value:'api',
                        label:'后台守卫'
                    }
                ],
                tableData: [],
                addPermission: false,
                addData: {
                    name: '',
                    display_name: '',
                    guard_name: '',
                    route: '',
                    parent_id:'',
                    id:'',
                },
                rules:{
                    name: [
                        {
                            required: true,
                            message: '请输入权限名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    parent_id: [
                        {
                            required: true,
                            message: '请选择父级权限',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    display_name: [
                        {
                            required: true,
                            message: '请输入名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    guard_name: [
                        {
                            required: true,
                            message: '请选着守卫',
                            trigger: ['change', 'blur'],
                        },
                    ],
                }
            }
        },
        mounted() {
            this.getPermissionList()
        },
        created(){

        },
        methods: {
            async getPermissionInfo(id){
                this.title = '编辑权限'
                await Api.model.show('permission',id).then((res) => {
                    this.$nextTick(() => {
                        this.addData = res
                        if(res.parent_id==0){
                            this.addData.parent_id = [res.parent_id]
                        }else{
                            this.addData.parent_id = res.parent_id
                        }

                        console.log(this.addData)
                    })
                })
            },
            async getPermissionList(){
                await Api.model.index('permission',{}).then((res)=>{
                    let permission =[{id:0,display_name:'顶级权限'}]
                    this.tableData = res
                    res.forEach((item)=>{
                        permission.push(item)
                    })
                    this.permissionOptions = permission
                })
            },
            //显示权限表单
            showPermissionForm(form){
                this.addPermission=true
                this.resetForm(form)
            },
            //savePermissionData
            async savePermissionData(form){
                let params = this.addData
                let parent_id = params.parent_id;
                if (typeof parent_id[0] != "undefined") {
                    parent_id = parent_id.slice(-1)[0];
                }
                params.parent_id = parent_id
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        if(this.addData.id){
                            await Api.model.update('permission',params, params.id)
                        }else{
                            await Api.model.store('permission',params)
                        }
                        this.success('成功')
                        this.addPermission = false
                        this.resetForm(form)
                        this.getPermissionList()
                    }
                });
            },
            deleteItem() {
                this.$confirm("确认删除此权限？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
        }
    }
</script>
