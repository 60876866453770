<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;padding-bottom:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <el-button size="small" type="primary" @click="exportData" v-show="allow('payment.update')"><i class="el-icon-download"></i> 导出</el-button>
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <!--<el-form-item>-->
                                        <!--<el-date-picker-->
                                                <!--v-model="filter.timerange"-->
                                                <!--value-format="yyyy-MM-dd HH:mm:ss"-->
                                                <!--format="yyyy-MM-dd HH:mm:ss"-->
                                                <!--type="daterange"-->
                                                <!--align="right"-->
                                                <!--unlink-panels-->
                                                <!--range-separator="-"-->
                                                <!--start-placeholder="完工开始日期"-->
                                                <!--end-placeholder="完工结束日期"-->
                                                <!--:picker-options="pickerOptions"-->
                                        <!--&gt;-->
                                        <!--</el-date-picker>-->
                                    <!--</el-form-item>-->
                                    <el-form-item>
                                        <el-date-picker
                                                v-model="filter.payTimerange"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="daterange"
                                                align="right"
                                                unlink-panels
                                                range-separator="-"
                                                start-placeholder="支付开始日期"
                                                end-placeholder="支付结束日期"
                                                :picker-options="pickerOptions"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="filter.status" clearable filterable placeholder="支付状态">
                                            <el-option
                                                    v-for="item in payStatusOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="filter.payEvidence" clearable filterable placeholder="支付凭证">
                                            <el-option
                                                    v-for="item in payEvidenceOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="filter.invoice" clearable filterable placeholder="电子发票">
                                            <el-option
                                                    v-for="item in invoiceOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入关键字"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getPaymentList"><i class="el-icon-search"></i></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="table-list">
                        <el-table :data="tableData">
                            <!--<el-table-column-->
                                    <!--type="selection"-->
                                    <!--width="55"-->
                                    <!--align="center">-->
                            <!--</el-table-column>-->

                            <el-table-column
                                    prop="task.name"
                                    :show-overflow-tooltip="true"
                                    label="子任务名称"
                                    min-width="20%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="task.serial_code"
                                    :show-overflow-tooltip="true"
                                    label="子任务编号"
                                    align="center"
                                    min-width="15%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    width="80"
                                    :show-overflow-tooltip="true"
                                    align="center"
                                    label="支付状态"
                            >
                                <template slot-scope="scope">
                                    <el-tag size="small" type="success" v-if="scope.row.status == 1">已支付</el-tag>
                                    <el-tag size="small" type="danger" v-else>未支付</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="payed_at"
                                    :show-overflow-tooltip="true"
                                    label="支付日期"
                                    align="center"
                                    width="180"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="proof_path"
                                    width="100"
                                    align="center"
                                    label="支付凭证"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="openWindowBlank(scope.row.proof_path)"
                                             v-if="scope.row.proof_path != '' && scope.row.proof_path!=null"><i
                                            class="el-icon-document"></i></el-link>
                                    <el-upload
                                            v-show="allow('payment.update')"
                                            v-else
                                            class="uploadThumbImg"
                                            :action="uploadURL"
                                            :headers="uploadHeaders"
                                            :on-success="handleProofUploadSuccess"
                                            :data="{module:'payment',model_id:scope.row.id}"
                                            :limit="1">
                                        <i class="el-icon-upload2"></i>
                                    </el-upload>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="invoice_path"
                                    width="100"
                                    align="center"
                                    label="电子发票"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="openWindowBlank(scope.row.invoice_path)"
                                             v-if="scope.row.invoice_path != '' && scope.row.invoice_path != null"><i
                                            class="el-icon-document"></i></el-link>
                                    <el-upload
                                            v-show="allow('payment.update')"
                                            v-else
                                            class="uploadThumbImg"
                                            :action="uploadURL"
                                            :headers="uploadHeaders"
                                            :on-success="handleInvoiceUploadSuccess"
                                            :data="{module:'payment',model_id:scope.row.id}"
                                            :limit="1">
                                        <i class="el-icon-upload2"></i>
                                    </el-upload>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="remark"
                                    :show-overflow-tooltip="true"
                                    label="备注"
                                    min-width="20%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="created_at"
                                    :show-overflow-tooltip="true"
                                    label="创建日期"
                                    align="center"
                                    width="180"
                            >
                            </el-table-column>
                            <el-table-column align="center" label="操作" width="80">
                                <template slot-scope="scope">
                                    <el-button type="text" title="编辑"
                                               v-show="allow('payment.update')"
                                               @click="showPaymentForm(scope.row);addProgram = true"><i
                                            class="el-icon-edit"></i></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="footer_container is-sticky-bottom">
                        <el-pagination
                                :current-page="currentPage"
                                :pager-count="5"
                                :page-size="pageSize"
                                layout="total, prev, pager, next"
                                :total="total"
                                @current-change="currentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <!-- 新建项目 -->
        <el-dialog
                custom-class="dialog_tc"
                title="设置完工支付"
                :visible.sync="addProgram"
                width="480px"
        >
            <el-form ref="addData" :model="addData" label-width="80px" size="medium">
                <el-form-item label="支付状态" required>
                    <el-switch
                            v-model="addData.status"
                            active-color="#13ce66"
                            :active-value="1"
                            :disabled="addData.status==1?true:false"
                            :inactive-value="2"
                            inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <el-form-item label="支付时间" required v-show="addData.status==1">
                    <el-date-picker
                            v-model="addData.payed_at"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择支付日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="支付凭证" v-show="addData.status==1">
                    <el-upload

                            class="uploadThumbImg"
                            :action="uploadURL"
                            :headers="uploadHeaders"
                            :on-success="handleAddProofUploadSuccess"
                            accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                            :data="{module:'payment'}"
                            :file-list="addData.payEvidence"
                            :limit="1">
                        <div class="flex">
                            <el-button size="medium" plain>点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="margin-left:8px;margin-top:0;">只支持上传pdf文件
                            </div>
                        </div>
                    </el-upload>
                    <!--<el-upload-->
                            <!--class="uploadThumbImg"-->
                            <!--action="https://jsonplaceholder.typicode.com/posts/"-->
                            <!--:limit="1"-->
                            <!--:file-list="addData.payEvidence">-->
                        <!--<div class="flex">-->
                            <!--<el-button size="medium" plain>点击上传</el-button>-->
                            <!--<div slot="tip" class="el-upload__tip" style="margin-left:8px;margin-top:0;">只支持上传pdf文件-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</el-upload>-->
                </el-form-item>
                <el-form-item label="电子发票" v-show="addData.status==1">
                    <el-upload

                            class="uploadThumbImg"
                            :action="uploadURL"
                            :headers="uploadHeaders"
                            :on-success="handleAddInvoiceUploadSuccess"
                            accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                            :data="{module:'payment'}"
                            :file-list="addData.invoice"
                            :limit="1">
                        <div class="flex">
                            <el-button size="medium" plain>点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="margin-left:8px;margin-top:0;">只支持上传pdf文件
                            </div>
                        </div>
                    </el-upload>
                    <!--<el-upload-->
                            <!--class="uploadThumbImg"-->
                            <!--:action="uploadURL"-->
                            <!--:headers="uploadHeaders"-->
                            <!--:on-success="handleInvoiceUploadSuccess"-->
                            <!--:limit="1"-->
                            <!--:file-list="addData.invoice"-->
                    <!--&gt;-->
                        <!--<div class="flex">-->
                            <!--<el-button size="medium" plain>点击上传</el-button>-->
                            <!--<div slot="tip" class="el-upload__tip" style="margin-left:8px;margin-top:0;">只支持上传pdf文件-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</el-upload>-->
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                            type="textarea"
                            :rows="3"
                            v-model="addData.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addProgram = false">取 消</el-button>
        <el-button size="small" type="primary" @click="savePaymentData"
        >保存</el-button
        >
      </span>
        </el-dialog>
    </div>
</template>
<script>
    // let id = '1000'
    import mixinPage from '@/mixins/page'
    import Api from '@/api'

    export default {
        mixins: [mixinPage],
        data() {
            return {
                title: '新建项目',
                currentPage: 1,
                pageSize: 15,
                total: 0,
                payStatusOptions: [{
                    value: "1",
                    label: "已支付"
                }, {
                    value: "2",
                    label: "未支付"
                }],
                payEvidenceOptions: [{
                    value: "1",
                    label: "有"
                }, {
                    value: "2",
                    label: "无"
                }],
                invoiceOptions: [{
                    value: "1",
                    label: "有"
                }, {
                    value: "2",
                    label: "无"
                }],
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "最近一周",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                        {
                            text: "最近一个月",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                        {
                            text: "最近三个月",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                    ],
                },
                filter: {
                    status: "",
                    payEvidence: "",
                    keyWord:'',
                    invoice: "",
                    timerange: ["", ""],
                    payTimerange: ["", ""],
                },
                tableData: [],
                addProgram: false,
                addData: {
                    status: "",
                    payed_at: "",
                    payEvidence: [],
                    invoice: [],
                    proof_path: '',//凭证路径
                    invoice_path: '',//发表路径
                    remark: '',
                    id: '1',
                },
            }
        },
        mounted() {
            this.getPaymentList()
        },
        created() {

        },
        methods: {
            showPaymentForm(item) {
                this.addData = item
                if(item.proof_path!='' && item.proof_path!=null){
                    this.addData.payEvidence = [
                        {
                            name:item.task.name+'支付凭证',
                            url:item.proof_path,
                        }
                    ]
                }
                if(item.invoice_path!='' && item.invoice_path!=null){
                    this.addData.invoice = [
                        {
                            name:item.task.name+'电子发票',
                            url:item.invoice_path,
                        }
                    ]
                }


            },
            async savePaymentData() {
                let params = this.addData
                await Api.model.update('payment', params, this.addData.id).then(res => {
                    this.success()
                    this.getPaymentList()
                    this.addProgram=false
                })
            },
            async handleAddInvoiceUploadSuccess(response){
                if (response.code == 200) {
                    this.addData.invoice_path = response.data.uri
                }
            },
            async handleInvoiceUploadSuccess(response) {
                if (response.code == 200) {
                    let params = {
                        invoice_path: response.data.uri
                    }
                    //修改
                    await Api.model.update('payment', params, response.data.model_id).then(res => {
                        this.getPaymentList();
                    })
                }
            },
            async handleAddProofUploadSuccess(response){
                if (response.code == 200) {
                    this.addData.proof_path = response.data.uri
                }
            },
            async handleProofUploadSuccess(response) {
                if (response.code == 200) {
                    let params = {
                        proof_path: response.data.uri
                    }
                    //修改
                    await Api.model.update('payment', params, response.data.model_id).then(res => {
                        this.getPaymentList();
                    })
                }
            },
            //改变当前页码
            currentChange(currentPage) {
                this.currentPage = currentPage
                this.getPaymentList()
            },
            async exportData(){
                let param = {
                    page:this.currentPage,
                    pageSize: this.pageSize,
                    status:this.filter.status,
                    timerange:this.filter.timerange,
                    payTimerange:this.filter.payTimerange,
                    invoice:this.filter.invoice,
                    keyWord:this.filter.keyWord,
                    payEvidence:this.filter.payEvidence,
                    export:1
                }
                await Api.model.index('payment', param,'blob').then(res => {
                   this.download(res,'完工支付.xls')
                })

                // let url = this.apiBaseURL + "/api/payment";
                //
                // if (param != {}) {
                //     let p = "";
                //     for (let key in param) {
                //         if(Array.isArray(param[key])){
                //             for(let i in param[key]){
                //                 console.log( param[key][i])
                //                 p += key+'[]' + "=" + param[key][i]+'&';
                //             }
                //
                //         }else{
                //             console.log(333)
                //             p += key + "=" + param[key]+'&';
                //         }
                //
                //     }
                //     url += "?" + p;
                // }
                // window.open(url);
            },


            async getPaymentList() {
                let params = {
                    page:this.currentPage,
                    pageSize: this.pageSize,
                    status:this.filter.status,
                    timerange:this.filter.timerange,
                    payTimerange:this.filter.payTimerange,
                    invoice:this.filter.invoice,
                    keyWord:this.filter.keyWord,
                    payEvidence:this.filter.payEvidence,
                }

                console.log(params)
                await Api.model.index('payment', params).then(res => {
                    this.tableData = res.data
                    this.total = res.total
                })
            }
        }
    }
</script>
