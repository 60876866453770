<template>
  <div class="tinymce-editor">
    <editor
      v-if="reFresh"
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick"
    >
    </editor>
  </div>
</template>

<script>
//这个是全功能版本的编辑器
// import api from "@/api";
import Editor from "@tinymce/tinymce-vue";
import tinymce from "../../../public/tinymce/tinymce";
import 'tinymce/themes/silver/theme'
import "tinymce/skins/ui/oxide/skin.min.css";

export default {
  components: {
    Editor,
  },
  props: {
    //传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //初始化配置
      init: {
        base_url:'/tinymce',
        external_plugins: {
          anchor: "/tinymce/plugins/anchor/plugin.min.js",
          code: "/tinymce/plugins/code/plugin.min.js",
          print: "/tinymce/plugins/print/plugin.min.js",
          preview: "/tinymce/plugins/preview/plugin.min.js",
          searchreplace: "/tinymce/plugins/searchreplace/plugin.min.js",
          autolink: "/tinymce/plugins/autolink/plugin.min.js",
          directionality: "/tinymce/plugins/directionality/plugin.min.js",
          visualblocks: "/tinymce/plugins/visualblocks/plugin.min.js",
          visualchars: "/tinymce/plugins/visualchars/plugin.min.js",
          fullscreen: "/tinymce/plugins/fullscreen/plugin.min.js",
          image: "/tinymce/plugins/image/plugin.min.js",
          link: "/tinymce/plugins/link/plugin.min.js",
          media: "/tinymce/plugins/media/plugin.min.js",
          template: "/tinymce/plugins/template/plugin.min.js",
          code: "/tinymce/plugins/code/plugin.min.js",
          codesample: "/tinymce/plugins/codesample/plugin.min.js",
          table: "/tinymce/plugins/table/plugin.min.js",
          charmap: "/tinymce/plugins/charmap/plugin.min.js",
          pagebreak: "/tinymce/plugins/pagebreak/plugin.min.js",
          nonbreaking: "/tinymce/plugins/nonbreaking/plugin.min.js",
          anchor: "/tinymce/plugins/anchor/plugin.min.js",
          insertdatetime: "/tinymce/plugins/insertdatetime/plugin.min.js",
          advlist: "/tinymce/plugins/advlist/plugin.min.js",
          lists: "/tinymce/plugins/lists/plugin.min.js",
          wordcount: "/tinymce/plugins/wordcount/plugin.min.js",
          imagetools: "/tinymce/plugins/imagetools/plugin.min.js",
          textpattern: "/tinymce/plugins/textpattern/plugin.min.js",
          help: "/tinymce/plugins/help/plugin.min.js",
          emoticons: "/tinymce/plugins/emoticons/plugin.min.js",
          autosave: "/tinymce/plugins/autosave/plugin.min.js",
          iframe: "/tinymce/plugins/iframe/plugin.min.js",
          hr: "/tinymce/plugins/hr/plugin.min.js",
          // formatpainter: "/tinymce/plugins/formatpainter/plugin.min.js",
        },
        language_url:
          "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",

        toolbar: [
          "template code undo redo restoredraft cut copy paste pastetext forecolor backcolor bold italic underline strikethrough link unlink anchor alignleft aligncenter alignright alignjustify outdent indent formatselect fontselect fontsizeselect bullist numlist blockquote subscript superscript removeformat table image media charmap emoticons pagebreak insertdatetime print preview fullscreen formatpainter iframe hr",
        ],
        // templates: [],
        // content_css : ['/layui/css/layui.css','/css/public.css?v=1'],
        // content_css : ['http://global.sjtu.demo.alawang.com/public/layui/css/layui.css','http://global.sjtu.demo.alawang.com/public/css/public_tinymce.css'],
        height: 800, //编辑器高度
        min_height: 200,
        max_height: 600,
        branding: false,
        paste_data_images: true, // 允许粘贴图像
        file_picker_types: "file image media",
        images_upload_handler: function (blobInfo, succFun, failFun) {
          var xhr, formData;
          var file = blobInfo.blob(); //转化为易于理解的file对象
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open("POST", "http://alasite.demo.alawang.com/api/upload");
          xhr.onload = function () {
            var json;
            json = JSON.parse(xhr.responseText);
            succFun("http://alasite.demo.alawang.com" + json.data.uri);
          };
          formData = new FormData();
          formData.append("file", file, file.name);
          formData.append("module", "post");
          xhr.send(formData);
        },
        media_url_resolver: function(data, resolve) {
          try {
            let videoUri = encodeURI(data.url);
            let embedHtml = `<p>
                  <span
                    class="mce-object mce-object-video"
                    data-mce-selected="1"
                    data-mce-object="video"
                    data-mce-p-width="100%"
                    data-mce-p-height="auto"
                    data-mce-p-controls="controls"
                    data-mce-p-controlslist="nodownload"
                    data-mce-p-allowfullscreen="true"
                    data-mce-p-src=${videoUri} >
                    <video src=${data.url} width="100%" height="auto" controls="controls" controlslist="nodownload">
                    </video>
                  </span>
                </p>
                <p style="text-align: left;"></p>`;
            resolve({ html: embedHtml });
          } catch (e) {
            resolve({ html: "" });
          }
        },
        file_picker_callback(cb, value, meta) {
          //当点击meidia图标上传时,判断meta.filetype == 'media'有必要，因为file_picker_callback是media(媒体)、image(图片)、file(文件)的共同入口
          console.log('meta',meta);
          
            //创建一个隐藏的type=file的文件选择input
            let input = document.createElement("input");
            input.setAttribute("type", "file");
            input.onchange = function () {
              var xhr, formData;
              let file = this.files[0]; //只选取第一个文件。如果要选取全部，后面注意做修改
              xhr = new XMLHttpRequest();
              xhr.withCredentials = false;
              xhr.open("POST", "http://alasite.demo.alawang.com/api/upload");
              xhr.onload = function () {
                var json;
                json = JSON.parse(xhr.responseText);
              };
              formData = new FormData();
              formData.append("file", file, file.name);
              formData.append("module", "post");
              xhr.send(formData);
              
              xhr.upload.onprogress = function () {
                // 进度(e.loaded / e.total * 100)
                progress(e.loaded / e.total * 100);
              };
              xhr.onload = function () {
                let json = JSON.parse(xhr.responseText);
                if (json.code == 200) {
                  if (meta.filetype == "media") {
                    cb(json.data.url, {text: json.data.name});
                  };
                  if (meta.filetype == "file") {
                    cb(json.data.url, {text: json.data.name});
                  };
                  return;
                }
              };
            };
            //触发点击
            input.click();
          
        },
      },
      myValue: this.value,
      laws: [],
      reFresh:true
    };
  },
  async mounted() {
    tinymce.init({});
  },
  methods: {
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    onClick(e) {
      this.$emit("onClick", e, tinymce);
    },
    //可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = "";
    },
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    },
    "init.templates"(newValue) {
      this.reFresh = false;
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
  },

};
</script>
<style scoped>
</style>