<template>
  <div class="login">
    <div class="login-container">
      <div class="login-logo">
        <!-- <img src="../../assets/images/logo.png" class="logo" alt="" /> -->
        后台信息管理系统
      </div>
      <div class="login-wrapper">
        <div class="login-title">重置密码</div>
        <el-form :model="resetForm" status-icon :rules="rules" ref="resetForm">
          <el-form-item prop="phone">
            <el-input
              type="text"
              v-model="resetForm.phone"
              placeholder="请输入手机号"
            >
              <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
            </el-input>
          </el-form-item>
          <el-form-item class="flex-item" prop="smsCode">
            <el-input
              type="text"
              v-model="resetForm.smsCode"
              placeholder="请输入验证码"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-chat-dot-round"
              ></i>
            </el-input>
            <el-button>发送验证码</el-button>
            <!-- <el-button disabled type="info" plain>倒计时 60s</el-button> -->
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="resetForm.password"
              placeholder="请输入新密码"
            >
              <i slot="prefix" class="el-input__icon el-icon-key"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('resetForm')"
              >立即重置密码</el-button
            >
            <div class="tip-text">
              想起密码了？<router-link to="/login">立即登录</router-link>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var isPhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!value) {
        callback(new Error("请输入手机号"));
      }
      setTimeout(() => {
        if (!reg.test(value) && value != "") {
          callback(new Error("手机格式不正确"));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      resetForm: {
        phone: "",
        smsCode: "",
        password: ""
      },
      rules: {
        phone: [{ validator: isPhone, trigger: "blur" }],
        smsCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 30,
            message: "长度在 6 到 30 个字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
@import "../../assets/css/login.css";
</style>
