<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;padding-bottom:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <el-button size="small" type="primary" @click="showCustomerForm" v-show="allow('customer.store')"><i class="el-icon-plus"></i> 新建企业
                            </el-button>
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-select
                                                v-model="filter.province"
                                                clearable
                                                filterable
                                                placeholder="请选择省份"
                                        >
                                            <el-option
                                                    v-for="item in province"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- <el-form-item>
                                        <el-select
                                                v-model="filter.company_id"
                                                clearable
                                                filterable
                                                placeholder="请选择园区"
                                        >
                                            <el-option
                                                    v-for="item in parkOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item> -->
                                    <el-form-item>
                                        <el-input placeholder="请输入关键字" v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getCustomerList"
                                        ><i class="el-icon-search"></i
                                        ></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="table-list">
                        <el-table :data="tableData">
                            <el-table-column
                                    prop="id"
                                    :show-overflow-tooltip="true"
                                    align="center"
                                    label="ID"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    label="企业名称"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="code"
                                    :show-overflow-tooltip="true"
                                    label="企业代号"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="serial_code"
                                    :show-overflow-tooltip="true"
                                    label="企业编号"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="tax_code"
                                    :show-overflow-tooltip="true"
                                    label="税号"
                            ></el-table-column>
                            <el-table-column
                                    prop="contact_people_name"
                                    :show-overflow-tooltip="true"
                                    label="联系人"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="contact_people_phone"
                                    :show-overflow-tooltip="true"
                                    label="联系电话"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    :show-overflow-tooltip="true"
                                    label="地址"
                            ></el-table-column>
                            <el-table-column
                                    prop="project_count"
                                    :show-overflow-tooltip="true"
                                    align="center"
                                    label="项目"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="toProgram(scope.row)">
                                        {{scope.row.project_count}}
                                    </el-link>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    :show-overflow-tooltip="true"
                                    align="center"
                                    label="状态"
                            >
                                <template slot-scope="scope">
                                    <el-tag
                                            size="small"
                                            type="success"
                                            v-if="scope.row.status == 1"
                                    >正常
                                    </el-tag>
                                    <el-tag size="small" type="danger" v-else>暂停</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" width="100">
                                <template  slot-scope="scope">
                                    <el-button v-show="allow('customer.update')"
                                            type="text"
                                            title="编辑"
                                            @click="getCustomerInfo(scope.row.id);showCustomerForm()"
                                    ><i class="el-icon-edit"></i>
                                    </el-button>
                                    <el-button type="text" title="删除" v-show="allow('customer.destroy')" @click="deleteItem"><i class="el-icon-delete"></i>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="footer_container is-sticky-bottom">
                        <el-pagination
                                :current-page="currentPage"
                                :pager-count="5"
                                :page-size="pageSize"
                                layout="total, prev, pager, next"
                                :total="total"
                                @current-change="currentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>

        <!-- 新建企业 -->
        <el-dialog
                custom-class="dialog_tc"
                :title="title"
                :visible.sync="addEnterprise"
                width="480px"
        >
            <el-form
                    ref="customerData"
                    :model="addData"
                    :rules="rules"
                    label-width="100px"
                    size="medium"
            >
                <el-form-item label="企业名称" prop="name">
                    <el-input
                            type="text"
                            placeholder="请输入企业名称"
                            v-model="addData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业代号" prop="code">
                    <el-input
                            type="text"
                            placeholder="请输入企业代号，一旦填写无法修改，请确认无误"
                            v-model="addData.code"
                            @change="changeCode"
                    ></el-input>
                </el-form-item>
                <el-form-item label="编号" prop="serial_code">
                    <el-input
                            type="text"
                            placeholder="请输入编号"
                            v-model="addData.serial_code"
                            disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业税号" prop="tax_code">
                    <el-input
                            type="text"
                            placeholder="请输入企业税号"
                            v-model="addData.tax_code"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业地址" prop="address">
                    <el-input
                            type="text"
                            placeholder="请输入企业地址"
                            v-model="addData.address"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="园区" prop="company_id">
                    <el-select
                            v-model="addData.company_id"
                            placeholder="请选择"
                            filterable
                            clearable
                    >
                        <el-option
                                v-for="item in parkOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="省份" required prop="province">
                    <el-select
                            v-model="addData.province"
                            placeholder="请选择"
                            filterable
                            clearable
                    >
                        <el-option
                                v-for="item in province"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系人" required>
                    <el-input
                            type="text"
                            placeholder="请输入联系人"
                            v-model="addData.contact_people_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" required>
                    <el-input
                            type="text"
                            placeholder="请输入联系电话"
                            v-model="addData.contact_people_phone"
                    ></el-input>
                </el-form-item>
                <el-form-item label="状态" required prop="status">
                    <el-radio-group v-model="addData.status">
                        <el-radio :label="1">正常</el-radio>
                        <el-radio :label="2">暂停</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addEnterprise = false">取 消</el-button>
        <el-button size="small" type="primary" v-if="addData.id" @click="editCustomerData('customerData')">保存</el-button>
        <el-button size="small" type="primary" v-else  @click="saveCustomerData('customerData')">保存</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<script>
    // let id = '1000'
    import { provinceAndCityData,CodeToText,TextToCode } from 'element-china-area-data'
    import Api from '@/api'
    import mixinPage from '@/mixins/page'

    export default {
        mixins: [mixinPage],
        data() {
            return {
                CodeToText:CodeToText,
                TextToCode:TextToCode,
                title:'新建企业',
                province:provinceAndCityData,
                addEnterprise: false,
                currentPage: 1,
                pageSize: 15,
                total: 0,
                parkOptions:[],
                filter: {
                    keyWord: '',
                    company_id: '',
                    province: '',

                },
                tableData: [],
                addData: {
                    name: '',
                    code: '',
                    serial_code: '',
                    tax_code:'',
                    address: '',
                    company_id: '',
                    province: '',
                    status: '',
                    contact_people_name:'',
                    contact_people_phone:'',
                    id:''
                },
                rules: {
                    name: [
                        {
                            required: true,
                            message: '请输入组织名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    code: [
                        {
                            required: true,
                            message: '请输入企业代号',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    serial_code: [
                        {
                            required: true,
                            message: '请输入编号',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    tax_code: [
                        {
                            required: true,
                            message: '请输入企业税号',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    address: [
                        {
                            required: true,
                            message: '请输入公司地址',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    company_id: [
                        {
                            required: true,
                            message: '请选择所属园区',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    province: [
                        {
                            required: true,
                            message: '请选择省份',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    status: [
                        {
                            required: true,
                            message: '请选择状态',
                            trigger: ['change', 'blur'],
                        },
                    ],
                },
            }
        },
        mounted() {
            //获取企业列表
            this.getCustomerList()
        },
        created() {
            //获取园区列表
            this.getCompanyList()
        },
        methods: {
            changeCode(vale){
                this.addData.serial_code = "JK-"+vale
            },
            //保存企业信息
            async saveCustomerData(form){
                let params = this.addData
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        await Api.customer.store(params).then((res)=>{
                            this.success('成功')
                            this.addEnterprise = false
                            this.resetForm(form)
                            this.getCustomerList()
                        }).catch((err)=>{
                            console.log(err)
                        })
                    }
                });

            },
            //修改企业信息
            async editCustomerData(form){

                let params = this.addData
                params.province  = this.CodeToText[params.province]
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        await Api.customer.update(params).then((res)=>{
                            this.success('成功')
                            this.addEnterprise = false
                            this.resetForm(form)
                            this.getCustomerList()
                        }).catch((err)=>{
                            console.log(err)
                        })
                    }
                });

            },
            //获取园区列表
            async getCompanyList() {
                await Api.company.index().then((res)=>{
                   let parkOptions = []
                    res.data.forEach(item=>{
                        parkOptions.push({
                            value:item.id,
                            label:item.park_name,
                        })
                    })
                    this.parkOptions = parkOptions
                })
            },
            //显示当前弹框
            showCustomerForm() {
                this.addEnterprise = true
                this.resetForm('customerData')
            },
            //获取单条企业详情
            async getCustomerInfo(customer_id){
                this.title = '编辑企业'
                await Api.customer.show(customer_id).then((res)=>{
                    this.$nextTick(()=>{
                        this.addData = res
                        this.addData.province = this.TextToCode[res.province].code
                    })

                })
            },
            //改变当前页码
            currentChange(currentPage) {
                this.currentPage = currentPage
                this.getCustomerList()
            },
            //获取企业列表
            async getCustomerList() {

                let params = {
                    page: this.currentPage,
                    pageSize: this.pageSize,
                    company_id:this.filter.company_id?this.CodeToText[params.province]:'',
                    province:this.filter.province,
                    keyWord:this.filter.keyWord,
                }

                await Api.customer.index(params).then((res) => {
                    this.tableData = res.data
                    this.total = res.total
                })
            },
            toProgram(item) {
                this.$router.push({path:'/program',query:{customer_id:item.id}});
            },
            deleteItem() {
                this.$confirm('确认删除此条目？', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    })
            },
        },
    }
</script>
