<template>
    <div>
        <el-drawer
                :visible.sync="companyPriceView"
                class="drawer_tc"
                size="40%"
                direction="rtl"
                :before-close="closeDrawer"
        >
            <div class="drawer-title" slot="title">
                <div class="title">
                    对子公司价格表
                </div>
                <div v-show="allow('project.update')">
                    <el-button type="primary" size="mini" @click="quotationEdit" v-show="companyinitButtonShow" >初始化价格包</el-button>
                </div>
                <div v-if="companysaveButtonShow">
                    <el-button type="danger" size="mini" @click="saveProjectPriceTemplate">保存</el-button>
                </div>
            </div>
            <div class="content_container"  v-if="companyquotationData.length!=0">
                <el-scrollbar>
                    <div class="container">
                        <div class="table-list quotation-table">
                            <el-table
                                    :data="companyquotationData"
                                    border
                                    size="small"
                            >
                                <el-table-column
                                        prop="name"
                                        :show-overflow-tooltip="true"
                                        label="公司名称"
                                        width="240"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="rate"
                                        label="税率"
                                        align="center"
                                >
                                    <template slot-scope="scope">
                                        <el-input-number
                                                v-if="companyquotationEditable"
                                                v-model="scope.row.rate"
                                                :min="0" :max="100"
                                                controls-position="right"
                                                size="mini"
                                        ></el-input-number>
                                        <span v-else>{{scope.row.rate}}%</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import Api from '@/api'
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        props:{
            id:{
                type:Number,
            },
            companyPriceView:{
                type:Boolean,
                default:false
            }
        },
        created(){
            //获取当前项目对子公司模板价格
            this.companyquotationData=[]
        },
        mounted() {

        },
        watch:{
            id:function(newVal,oldVal){
                this.id = newVal
                this.companyinitButtonShow=false,
                    this.companysaveButtonShow=false,
                    this.companyquotationEditable=false,
                    this.companyquotationData=[]
                    this.getProjectCompanyPrice()
            }
        },
        data(){
            return {
                companyinitButtonShow:false,
                companysaveButtonShow:false,
                companyquotationEditable:false,
                companyquotationData:[],
                rate_name:'税率'
            }
        },
        methods:{
            closeDrawer(){
                this.$emit('closeDrawer')
            },
            //保存项目的价格包
            async saveProjectPriceTemplate(){
                this.confirm('确认无误提交后不可修改！！！',async ()=>{
                    let data = this.companyquotationData
                    let dataInfo = []
                    data.forEach(item=>{
                        dataInfo.push({
                            project_id:this.id,
                            company_id:item.id,
                            rate:item.rate
                        })
                    })
                    await Api.model.store('projectCompanyPrice',dataInfo).then(res=>{
                        this.getProjectCompanyPrice()
                        this.companysaveButtonShow = false
                    })
                })
            },
            //当前项目价格模板
            async getProjectCompanyPrice(){
                let params = {
                    project_id:this.id,
                }
                await Api.model.index('projectCompanyPrice',params).then(res=>{
                    this.companyquotationData=[]
                    if(res.length ==0){
                        this.companyinitButtonShow = true
                    }else{
                        this.companyquotationEditable=false
                        this.companyquotationData = res
                    }

                })
            },

            //获取初始化价格模板
            async quotationEdit(){
                let params = {
                    type:this.type
                }
                await Api.model.custom('api/projectCompanyPrice/company','get',params).then(res=>{
                    this.companyquotationData = res
                })
                this.companyinitButtonShow = false
                this.companysaveButtonShow = true
                this.companyquotationEditable = true
            },

        }
    }
</script>
<style>
    .swal2-container{
        z-index: 3000 !important;
    }
</style>
