<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;padding-bottom:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <!--<el-button size="small" type="primary"><i class="el-icon-download"></i> 批量下载</el-button>-->
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-select v-model="filter.type" clearable filterable placeholder="合同类型">
                                            <el-option
                                                    v-for="item in typeOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入关键字"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getAgreementList"><i class="el-icon-search"></i></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="table-list">
                        <el-table
                                :data="tableData"
                                @cell-click="toProgram">
                            <!--<el-table-column-->
                                    <!--type="selection"-->
                                    <!--width="55"-->
                                    <!--align="center">-->
                            <!--</el-table-column>-->
                            <el-table-column
                                    prop="id"
                                    :show-overflow-tooltip="true"
                                    width="55"
                                    align="center"
                                    label="编号"

                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    label="合同名称"
                                    min-width="20%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="type"
                                    :show-overflow-tooltip="true"
                                    label="类型"
                                    align="center"
                                    min-width="15%"
                            >
                                <template slot-scope="scope">
                                    <el-tag size="small" v-if="scope.row.type == 1">项目合同</el-tag>
                                    <el-tag size="small" type="success" v-else-if="scope.row.type == 2">医生合作</el-tag>
                                    <el-tag size="small" type="danger" v-else-if="scope.row.type == 3">服务商合作</el-tag>
                                    <el-tag size="small" type="warning" v-else>任务协议</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="signed_at"
                                    :show-overflow-tooltip="true"
                                    label="签署时间"
                                    align="center"
                                    min-width="15%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="signer"
                                    :show-overflow-tooltip="true"
                                    label="签署人"
                                    align="center"
                                    min-width="15%"
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.taker!=null?scope.row.taker.name:""}}</span>
                                </template>
                            </el-table-column>
                            <!--<el-table-column-->
                                    <!--prop="manager"-->
                                    <!--:show-overflow-tooltip="true"-->
                                    <!--label="负责人"-->
                                    <!--align="center"-->
                                    <!--min-width="15%"-->
                            <!--&gt;-->
                                <!--<template slot-scope="scope">-->
                                    <!--<span>{{scope.row.manager!=null?scope.row.manager.name:''}}</span>-->
                                <!--</template>-->
                            <!--</el-table-column>-->
                            <el-table-column align="center" label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button type="text" title="查看" @click="scope.row.complate_file_path!=null?openWindowBlank(scope.row.complate_file_path):openWindowBlank(scope.row.file_path)"><i class="el-icon-view"></i></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="footer_container is-sticky-bottom">
                        <el-pagination
                                :current-page="currentPage"
                                :pager-count="5"
                                :page-size="pageSize"
                                layout="total, prev, pager, next"
                                :total="total"
                                @current-change="currentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>

    </div>
</template>
<script>
    // let id = '1000'
    import Api from '@/api'
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                filter: {
                    keyWord: '',
                    type: "",
                },
                typeOptions: [{
                    value: "1",
                    label: "项目合同"
                }, {
                    value: "2",
                    label: "服务商合作"
                }, {
                    value: "3",
                    label: "医生合作"
                }, {
                    value: "4",
                    label: "任务协议"
                }],
                tableData: [],
            }
        },
        mounted() {
            //获取合同列表
            this.getAgreementList()
        },
        created(){

        },
        methods: {
            //改变当前页码
            currentChange(currentPage) {
                this.currentPage = currentPage
                this.getAgreementList()
            },
            //获取合同列表
            async getAgreementList(){
                let params  = {
                    page:this.currentPage,
                    pageSize: this.pageSize,
                    type:this.filter.type,
                    keyWord:this.filter.keyWord
                }
                await Api.model.index('agreement',params).then(res=>{
                    this.tableData = res.data
                    this.total = res.total
                })
            },
            toProgram(row, column, cell, event) {
                console.log(row);
                console.log(column);
                console.log(cell);
                console.log(event);
                console.log(column.property);
                if (column.property == 'programs') {
                    this.$router.push("/program");
                }
            },
            deleteItem() {
                this.$confirm("确认删除此条目？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
        }
    }
</script>
