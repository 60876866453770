<template>
    <div class="login">
        <div class="login-container">
            <div class="login-logo">
                健康云管理系统
            </div>
            <div class="login-wrapper">
                <div class="login-title">登录账号</div>
                <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm">
                    <el-form-item prop="phone">
                        <el-input
                                type="text"
                                v-model="loginForm.phone"
                                placeholder="请输入手机号"
                        >
                            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 30px" prop="password">
                        <el-input
                                type="password"
                                v-model="loginForm.password"
                                placeholder="请输入密码"
                        >
                            <i slot="prefix" class="el-input__icon el-icon-key"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" :loading="loadingFalse" @click="submitForm('loginForm')">
                            立即登录
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import mixinPage from '@/mixins/page'
    import Api from '@/api'
    import Setting from '@/setting';
    export default {
        mixins: [mixinPage],
        data() {
            var isPhone = (rule, value, callback) => {
                const reg = /^[1][0-9]{10}$/;
                if (!value) {
                    callback(new Error("请输入手机号"));
                }
                setTimeout(() => {
                    if (!reg.test(value) && value != "") {
                        callback(new Error("手机格式不正确"));
                    } else {
                        callback();
                    }
                }, 1000);
            };
            return {
                loadingFalse:false,
                loginForm: {
                    phone: "",
                    password: ""
                },
                rules: {
                    phone: [{validator: isPhone, trigger: "blur"}],
                    password: [
                        {required: true, message: "密码不能为空", trigger: "blur"},
                        {
                            min: 6,
                            max: 30,
                            message: "长度在 6 到 30 个字符",
                            trigger: "blur"
                        }
                    ]
                }
            };
        },
        methods: {
            ...mapActions('admin/account',['login']),
            //提交按钮
            submitForm(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {

                        await this.login({
                            username: this.loginForm.phone,
                            password: this.loginForm.password
                        });

                        let permission = await Api.system.getPermission('permission')

                        this.$store.dispatch('admin/permission/set',permission)

                        setTimeout(() => {
                            this.$router.replace(this.$route.query.redirect || '/');
                        });
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },

        }
    };
</script>
<style scoped>
    @import "../../assets/css/login.css";
</style>
