<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;padding-bottom:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <el-button size="small" v-show="allow('system.company.store')" type="primary" @click="showCompanyForm('companyData')"><i class="el-icon-plus"></i>
                                新建园区
                            </el-button>
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-select v-model="filter.province" clearable filterable
                                                   placeholder="请选择省份">
                                            <el-option
                                                    v-for="item in provinceOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入关键字"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getCompanyList"><i class="el-icon-search"></i></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <!--数据列表-->
                    <div class="table-list">
                        <el-table
                                :data="tableData">
                            <el-table-column
                                    prop="id"
                                    :show-overflow-tooltip="true"
                                    width="55"
                                    align="center"
                                    label="ID"
                            >
                            </el-table-column>

                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    label="子公司名称"
                                    min-width="20%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="is_sub"
                                    min-width="15%"
                                    :show-overflow-tooltip="true"
                                    align="center"
                                    label="是否为子公司"
                            >
                                <template slot-scope="scope">
                                    <el-tag size="small" type="success" v-if="scope.row.is_sub">是</el-tag>
                                    <el-tag size="small" type="danger" v-else>否</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="park_name"
                                    :show-overflow-tooltip="true"
                                    label="园区名称"
                                    min-width="20%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="province"
                                    :show-overflow-tooltip="true"
                                    label="省份"
                                    min-width="15%"
                            ></el-table-column>
                            <el-table-column align="center" label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button type="text" title="编辑" v-show="allow('system.company.update')"  @click="editShowCompanyForm('companyData');getCompanyInfo(scope.row.id);"><i
                                            class="el-icon-edit"></i></el-button>
                                    <el-button type="text" title="删除" v-show="allow('system.company.destroy')"  @click="deleteItem"><i class="el-icon-delete"></i>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <div class="footer_container is-sticky-bottom">
                        <el-pagination
                                :current-page="currentPage"
                                :pager-count="5"
                                :page-size="pageSize"
                                layout="total, prev, pager, next"
                                :total="total"
                                @current-change="currentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>

        <!-- 新建园区 -->
        <el-dialog
                custom-class="dialog_tc"
                title="新建园区"
                :visible.sync="addPark"
                width="480px"
        >
            <el-form ref="companyData" :rules="rules" :model="addData" label-width="170px" size="medium">
                <el-form-item label="公司名称" prop="name">
                    <el-input
                            type="text"
                            placeholder="请输入公司名称"
                            v-model="addData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="是子公司" prop="is_sub">
                    <el-radio-group v-model="addData.is_sub">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="园区名称" prop="park_name">
                    <el-input
                            type="text"
                            placeholder="请输入园区名称"
                            v-model="addData.park_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业社会统一信用代码" prop="credit_code">
                    <el-input
                            type="text"
                            placeholder="请输入企业社会统一信用代码"
                            v-model="addData.credit_code"
                    ></el-input>
                </el-form-item>
                <el-form-item label="法人姓名" prop="legal_person_name">
                    <el-input
                            type="text"
                            placeholder="请输入法人姓名"
                            v-model="addData.legal_person_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="法人手机号" prop="identify_mobile">
                    <el-input
                            type="text"
                            placeholder="请输入法人手机号"
                            v-model="addData.identify_mobile"
                    ></el-input>
                </el-form-item>
                <el-form-item label="法人身份证" prop="id_card">
                    <el-input
                            type="text"
                            placeholder="请输入法人身份证"
                            v-model="addData.id_card"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人手机号" prop="mobile">
                    <el-input
                            type="text"
                            placeholder="请输入联系人手机号"
                            v-model="addData.mobile"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人姓名" prop="contact_name">
                    <el-input
                            type="text"
                            placeholder="请输入联系人姓名"
                            v-model="addData.contact_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人身份证号" prop="contact_id_card">
                    <el-input
                            type="text"
                            placeholder="请输入联系人身份证号"
                            v-model="addData.contact_id_card"
                    ></el-input>
                </el-form-item>
                <el-form-item label="省份" prop="province">
                    <el-select v-model="addData.province" placeholder="请选择" filterable clearable>
                        <el-option
                                v-for="item in provinceOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addPark = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveCompanyData('companyData')">保存</el-button>
      </span>
        </el-dialog>

    </div>
</template>
<script>
    // let id = '1000'
    import Api from '@/api'
    import { provinceAndCityData ,CodeToText,TextToCode} from 'element-china-area-data'
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                title: '新建项目',
                currentPage: 1,
                pageSize: 15,
                total: 0,
                provinceOptions: provinceAndCityData,
                CodeToText:CodeToText,
                TextToCode:TextToCode,
                filter: {
                    keyWord: '',
                    province: '',
                },
                tableData: [],
                addPark: false,
                addData: {
                    id:'',
                    name: '',
                    is_sub: 1,
                    park_name: '',
                    province: "",
                    credit_code:'',
                    legal_person_name:'',
                    identify_mobile:'',
                    id_card:'',
                    mobile:'',
                    contact_name:'',
                    contact_id_card:'',
                },
                rules: {
                    name: [
                        {
                            required: true,
                            message: '请输入公司名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    is_sub: [
                        {
                            required: true,
                            message: '请选择是否是子公司',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    park_name: [
                        {
                            required: true,
                            message: '请输入园区名称',
                            trigger: ['change', 'blur']
                        }
                    ],
                    province: [
                        {
                            required: true,
                            message: '请选着省份',
                            trigger: ['change', 'blur']
                        }
                    ],
                    credit_code: [
                        {
                            required: true,
                            message: '请填写企业社会统一信用代码',
                            trigger: ['change', 'blur']
                        }
                    ],
                    legal_person_name: [
                        {
                            required: true,
                            message: '请填写法人姓名',
                            trigger: ['change', 'blur']
                        }
                    ],
                    identify_mobile: [
                        {
                            required: true,
                            message: '请填写法人手机号',
                            trigger: ['change', 'blur']
                        }
                    ],
                    id_card: [
                        {
                            required: true,
                            message: '请填写法人身份证',
                            trigger: ['change', 'blur']
                        }
                    ],
                    mobile: [
                        {
                            required: true,
                            message: '请填写联系人手机号',
                            trigger: ['change', 'blur']
                        }
                    ],
                    contact_name: [
                        {
                            required: true,
                            message: '请填写联系人姓名',
                            trigger: ['change', 'blur']
                        }
                    ],
                    contact_id_card: [
                        {
                            required: true,
                            message: '请填写联系人身份证号',
                            trigger: ['change', 'blur']
                        }
                    ],
                }
            }
        },
        mounted() {
            this.getCompanyList()
        },
        created() {

        },
        methods: {
            //改变当前页码
            currentChange(currentPage) {
                this.currentPage = currentPage
                this.getCompanyList()
            },
            //获取单条详情
            async getCompanyInfo(id) {
                this.title = '编辑园区'
                await Api.model.show('company',id).then((res) => {
                    let newData = {}
                    Object.keys(this.addData).forEach(item=>{
                        if(res[item]!=undefined){ //判断返回数据中是否存在该对象
                            newData[item] = res[item] //赋值给对象
                        }
                    })
                    this.addData = newData
                    this.addData.province = this.TextToCode[res.province].code
                    // this.$nextTick(() => {
                    //     this.addData = newData
                    //     console.log(this.addData)
                    //     this.addData.province = this.TextToCode[res.province].code
                    // })
                })
            },
            //显示
            showCompanyForm(form) {
                this.addPark = true
                this.addData.id = ''
                this.resetForm(form)
                console.log(this.addData)
            },
            //编辑显示
            editShowCompanyForm(form) {
                this.addPark = true
                console.log(this.addData)
                this.resetForm(form)

            },
            //保存信息
            saveCompanyData(form){
                let params = this.addData
                params.province  = this.CodeToText[params.province]
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        if(this.addData.id){
                            await Api.model.update('company',params, params.id)
                        }else{
                            await Api.model.store('company',params)
                        }
                        this.success('成功')
                        this.addPark = false
                        this.resetForm(form)
                        this.getCompanyList()
                    }
                });
            },
            //获取园区列表
            async getCompanyList() {
                let province = ''
                if(this.filter.province!=''){
                    province = this.CodeToText[this.filter.province]
                }
                let params = {
                    page:this.currentPage,
                    pageSize: this.pageSize,
                    province:province
                }
                await Api.model.index('company', params).then((res) => {
                    this.tableData = res.data
                    this.total = res.total
                })
            },
            toProgram(row, column, cell, event) {
                if (column.property == 'programs') {
                    this.$router.push("/program");
                }
            },
            deleteItem() {
                this.$confirm("确认删除此条目？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
        }
    }
</script>
