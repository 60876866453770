<template>
    <div>
        <el-drawer
                :visible.sync="quotationView"
                class="drawer_tc"
                size="40%"
                direction="rtl"
                :before-close="closeDrawer"
        >
            <div class="drawer-title" slot="title">
                <div class="title">
                    {{drawTitle}}
                </div>
                <div v-show="allow('project.update')">
                    <el-button type="primary" size="mini" @click="quotationEdit" v-show="initButtonShow">初始化价格包
                    </el-button>
                </div>
                <div v-if="saveButtonShow">
                    <!--<el-button type="danger" size="mini" plain @click="quotationEditable = false">取消</el-button>-->
                    <el-button type="danger" size="mini" @click="saveProjectPriceTemplate">保存</el-button>
                </div>
            </div>
            <div class="content_container" v-if="quotationData.length!=0">
                <el-scrollbar>
                    <div class="container">
                        <div class="table-list quotation-table">
                            <el-table
                                    :data="quotationData"
                                    :span-method="objectSpanMethod"
                                    border
                                    size="small"
                            >
                                <el-table-column
                                        prop="category_name"
                                        :show-overflow-tooltip="true"
                                        label="分类"
                                        width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="duration"
                                        :show-overflow-tooltip="true"
                                        label="时长"
                                        width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="duration_code"
                                        :show-overflow-tooltip="true"
                                        label="时长编码"
                                        align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="price"
                                        :label="priceDes"
                                        align="center"
                                >
                                    <template slot-scope="scope">
                                        <!-- <el-input
                                            v-if="quotationEditable"
                                            v-model="scope.row.price"
                                            size="mini"
                                        ></el-input> -->
                                        <el-input-number
                                                v-if="priceEdit"
                                                v-model="scope.row.price"
                                                controls-position="right"
                                                size="mini"
                                        ></el-input-number>
                                        <span v-else-if="type==2">{{scope.row.price}}</span>
                                        <span v-else-if="type==1">{{computePrice(scope.row.rate_price,scope.row.rate,scope.$index)}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="rate"
                                        :label="rate_name"
                                        align="center"
                                >
                                    <template slot-scope="scope">
                                        <el-input-number
                                                v-if="quotationEditable && type == 1"
                                                v-model="scope.row.rate"
                                                :min="0" :max="1000000"
                                                controls-position="right"
                                                size="mini"
                                        ></el-input-number>
                                        <el-input-number
                                                v-else-if="quotationEditable && type == 2"
                                                v-model="scope.row.rate"
                                                :min="0" :max="100"
                                                controls-position="right"
                                                size="mini"
                                        ></el-input-number>
                                        <span v-else >{{type==1?scope.row.rate:scope.row.rate+'%'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="rate_price"
                                        label="税后价"
                                        align="center"
                                >
                                    <template slot-scope="scope">
                                        {{type==1?scope.row.rate_price:computeRatePrice(scope.row.price,scope.row.rate,scope.$index)}}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import Api from '@/api'
    import mixinPage from '@/mixins/page'

    export default {
        mixins: [mixinPage],
        props: {
            id: {
                type: Number,
            },
            type: {
                type: Number,
            },
            drawTitle: {
                type: String
            },
            quotationView: {
                type: Boolean,
                default: false
            }
        },
        created() {
            //获取当前项目模板价格
            this.quotationData = []
        },
        mounted() {

        },
        watch: {
            type: function (newVal, oldVal) {
                this.type = newVal
                if (this.type == 1) {
                    this.rate_name = '税及服务'
                    this.priceDes = '单价'
                }
            },
            id: function (newVal, oldVal) {
                this.id = newVal
                this.initButtonShow = false,
                this.saveButtonShow = false,
                this.quotationEditable = false,
                this.priceEdit = false,
                this.getProjectPriceTemplate()
            }
        },
        data() {
            return {
                initButtonShow: false,
                saveButtonShow: false,
                quotationEditable: false,
                priceEdit: false,
                spanArr: [],//用于存放每一行记录的合并数
                quotationData: [],
                rate_name: '税率',
                priceDes:'企业报价',
            }
        },
        methods: {
            //计算视频单价
            computePrice(rate_price,rate,row){
                // console.log(row)
                // console.log(this.quotationData)
                return this.quotationData[row].price = parseFloat(rate_price)+parseFloat(rate)

            },
            computeRatePrice(price,rate,row){
                return this.quotationData[row].rate_price = price*(100-rate)/100
            },
            // s(){
            //     this.quotationData=[]
            // },
            closeDrawer() {

                this.$emit('closeDrawer')
            },
            //保存项目的价格包
            async saveProjectPriceTemplate() {
                this.confirm('确认无误提交后不可修改！！！', async () => {
                    let data = this.quotationData
                    await Api.model.store('projectPriceTemplate', data).then(res => {
                        this.getProjectPriceTemplate()
                        this.saveButtonShow = false
                    })
                })
            },
            //当前项目价格模板
            async getProjectPriceTemplate() {
                let params = {
                    project_id: this.id,
                    type: this.type
                }
                await Api.model.index('projectPriceTemplate', params).then(res => {
                    this.quotationData = []
                    this.spanArr = []
                    if (res.length == 0) {
                        this.initButtonShow = true
                    } else {
                        this.quotationEditable = false
                        this.priceEdit = false
                        this.quotationData = res
                        this.getSpanArr(res);
                    }

                })
            },
            getSpanArr(data) {
                // data就是我们从后台拿到的数据
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1);
                        this.pos = 0;
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].category_name === data[i - 1].category_name) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            },
            //获取初始化价格模板
            async quotationEdit() {
                let params = {
                    type: this.type
                }
                if (this.type == 1) {
                    params.project_id = this.id
                }
                await Api.model.index('priceTemplate', params).then(res => {
                    res.forEach((item) => {
                        item.project_id = this.id
                        delete item.id
                    })
                    this.quotationData = res
                    this.getSpanArr(this.quotationData)
                })
                this.initButtonShow = false
                this.saveButtonShow = true
                this.quotationEditable = true
                console.log(this.type)
                if(this.type == 2){
                    this.priceEdit = true
                }
            },
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    // console.log(`rowspan:${_row} colspan:${_col}`);
                    return {
                        // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col
                    };
                }
            },
        }
    }
</script>
<style>
    .swal2-container {
        z-index: 3000 !important;
    }
</style>
