<template>
    <div class="content_container">
                <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;padding-bottom:0;">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-select v-model="filter.type" clearable filterable placeholder="请选择企业">
                                            <el-option
                                                    v-for="item in filter.typeOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入关键字"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getTakerList"><i class="el-icon-search"></i></el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <!--table列表-->
                    <div class="table-list">
                        <el-table
                                :data="tableData">
                            <el-table-column
                                    prop="id"
                                    :show-overflow-tooltip="true"
                                    width="55"
                                    align="center"
                                    label="ID"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    label="名称"
                                    min-width="15%"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="doctorView(scope.row)">{{scope.row.name}}</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="type"
                                    :show-overflow-tooltip="true"
                                    label="类型"
                                    min-width="15%"
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.type == 1">医生</span>
                                    <span v-else>服务商</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="province"
                                    :show-overflow-tooltip="true"
                                    label="省市"
                                    min-width="15%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="department"
                                    :show-overflow-tooltip="true"
                                    label="单位"
                                    min-width="15%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="serial_code"
                                    :show-overflow-tooltip="true"
                                    label="编号"
                                    align="center"
                                    width="120"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="certification_status"
                                    :show-overflow-tooltip="true"
                                    label="认证状态"
                                    min-width="15%"
                                    align="center"
                            >
                                <template slot-scope="scope">
                                    <el-tag size="small" type="success" v-if="scope.row.certification_status">已认证</el-tag>
                                    <el-tag size="small" type="danger" v-else>未认证</el-tag>
                                </template>
                            </el-table-column>
                            <!--<el-table-column-->
                                    <!--prop="agreement"-->
                                    <!--width="100"-->
                                    <!--:show-overflow-tooltip="true"-->
                                    <!--align="center"-->
                                    <!--label="合同"-->
                            <!--&gt;-->
                                <!--<template slot-scope="scope">-->
                                    <!--<el-link type="primary" :href="getImgUrl(scope.row.agreement.file_path)"-->
                                             <!--v-if="scope.row.agreement != null"><i-->
                                            <!--class="el-icon-document"></i></el-link>-->
                                    <!--<el-upload-->
                                            <!--v-show="allow('taker.update')"-->
                                            <!--v-else-->
                                            <!--class="uploadThumbImg"-->
                                            <!--:action="uploadURL"-->
                                            <!--:headers="uploadHeaders"-->
                                            <!--:on-success="handleUploadSuccess"-->
                                            <!--:data="{module:'taker',model_id:scope.row.id}"-->
                                            <!--:limit="1">-->
                                        <!--<i class="el-icon-upload2"></i>-->
                                    <!--</el-upload>-->
                                <!--</template>-->
                            <!--</el-table-column>-->
                            <el-table-column
                                    prop="task_count"
                                    :show-overflow-tooltip="true"
                                    width="100"
                                    align="center"
                                    label="任务数"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="toSubTask(scope.row)">{{scope.row.task_count}}</el-link>
                                </template>
                            </el-table-column>
                            <!--<el-table-column-->
                                    <!--prop="status"-->
                                    <!--width="100"-->
                                    <!--:show-overflow-tooltip="true"-->
                                    <!--align="center"-->
                                    <!--label="状态"-->
                            <!--&gt;-->
                                <!--<template slot-scope="scope">-->
                                    <!--<el-tag size="small" type="warning" v-if="scope.row.status == 1">正常</el-tag>-->
                                    <!--<el-tag size="small" type="success" v-else-if="scope.row.status == 2">暂停</el-tag>-->
                                <!--</template>-->
                            <!--</el-table-column>-->
                            <el-table-column align="center" label="操作" width="120">
                                <template slot-scope="scope">
                                    <!--<el-button type="text" title="编辑" @click="addProgram = true"-->
                                               <!--v-show="allow('taker.update')"><i-->
                                            <!--class="el-icon-edit"></i></el-button>-->
                                    <!-- <el-button type="text" title="删除" @click="deleteItem"><i class="el-icon-delete"></i></el-button> -->
                                    <!--<el-button type="text"-->
                                               <!--v-show="allow('taker.update')"-->
                                               <!--:title="[scope.row.status==1? '启用' : '暂停']"-->
                                               <!--@click="isOpen(scope.row)"-->
                                    <!--&gt;-->
                                        <!--<i class="el-icon-open" v-if="scope.row.status==1"></i>-->
                                        <!--<i class="el-icon-turn-off" v-else></i>-->
                                    <!--</el-button>-->

                                    <el-button type="text"
                                               v-show="allow('taker.update')"
                                               :title="[scope.row.certification_status? '已认证' : '未认证']"
                                               @click="isCert(scope.row)"
                                               :class="{isGrey: !scope.row.certification_status}"
                                    >
                                        <i class="el-icon-s-claim"></i>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="footer_container is-sticky-bottom">
                        <el-pagination
                                :current-page="currentPage"
                                :pager-count="5"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total="total"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>

        <!-- 子任务列表 -->
        <subTask :drawer="subTaskDrawer" v-if="showSubTask" @changeDrawer="changeDrawer" :taker_id="taker_id" :create="createSubTask"></subTask>

        <!-- 医生详情 -->
        <el-drawer
                :visible.sync="doctorDrawer"
                class="drawer_tc"
                size="40%"
                direction="rtl"
        >
            <div class="drawer-title" slot="title">
                <div class="title">张医生</div>
            </div>
            <div class="content_container">
                <el-scrollbar>
                    <div class="container">
                        <el-descriptions
                                class="margin-top"
                                :column="2"
                                size="medium"
                                border
                        >
                            <el-descriptions-item :span="2">
                                <template slot="label"> 姓名 </template>
                                {{doctorDrawerData.name}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 手机号 </template>
                                {{doctorDrawerData.phone}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 身份证号 </template>
                                {{doctorDrawerData.passport}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 省市 </template>
                                {{doctorDrawerData.province}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 单位 </template>
                                {{doctorDrawerData.department}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 科室 </template>
                                外科
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 身份 </template>
                                <span v-if="doctorDrawerData.type == 1">医生</span>
                                <span v-else-if="doctorDrawerData.type == 2">服务商</span>
                            </el-descriptions-item>
                            <!--<el-descriptions-item :labelStyle="{'vertical-align': 'top'}">-->
                                <!--<template slot="label"> 介绍 </template>-->
                                <!--Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum ipsam vitae debitis perferendis libero provident beatae rem, sapiente, magni explicabo doloribus mollitia veritatis, quasi cum quisquam facilis aspernatur. Fugit, nostrum?-->
                            <!--</el-descriptions-item>-->
                        </el-descriptions>
                    </div>
                </el-scrollbar>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import subTask from "../../components/task/subTask";
    import Api from '@/api'
    import mixinPage from '@/mixins/page'

    export default {
        mixins: [mixinPage],
        components: {
            subTask,
        },
        data() {
            return {
                showSubTask:false,
                taker_id:0,
                doctorDrawerData:{},
                title: '新建项目',
                currentPage: 1,
                pageSize: 15,
                total: 0,
                doctorDrawer:false,
                activeName:'doctors',
                filter: {
                    keyword: '',
                    type: "",
                    typeOptions: [{
                        value: "1",
                        label: "医生"
                    }, {
                        value: "2",
                        label: "服务商"
                    }],
                },
                tableData: [],
                subTaskDrawer: false,
                createSubTask: false,
            }
        },
        created(){

        },
        mounted() {
            this.getTakerList()
        },
        methods: {
            //上传文件
            //文件上传成功后操作
            async handleUploadSuccess(response){
                if(response.code == 200){
                    let params = {
                        model_id:response.data.model_id,
                        module:response.data.module,
                        file_path:response.data.uri
                    }
                    //修改
                    await Api.taker.saveAgreement(params).then(()=>{
                        this.getTakerList();
                    })
                }
            },
            // 查看医生详情
            doctorView(item) {
                this.doctorDrawer = true;
                this.doctorDrawerData = item
            },
            async getTakerList(){
                let params = {
                    page:this.currentPage,
                    pageSize:this.pageSize,
                    type:this.filter.type,
                    keyWord:this.filter.keyWord
                }
                await Api.model.index('taker',params).then(res=>{
                   this.tableData = res.data
                    this.total = res.total
                })
            },
            toSubTask(item) {
                this.subTaskDrawer = true;
                this.taker_id = item.id
                this.showSubTask = true
            },
            changeDrawer(v) {
                this.subTaskDrawer = v;
                this.showSubTask = false
            },
            isOpen(row) {
                this.confirm(`确认${row.status == 1 ? '暂停' : '启用'}该猎手？`,()=>{
                    let status = row.status==1?2:1;
                    let params = {
                        status:status
                    }
                    Api.model.update('taker',params,row.id).then(()=>{
                        this.success('成功')
                        this.getTakerList()
                    });
                })

            },
            isCert(row) {
                this.confirm(`确认${row.certification_status == 1 ? '取消认证' : '认证'}该猎手？`,()=>{
                    let certification_status = row.certification_status==1?0:1;
                    let params = {
                        certification_status:certification_status
                    }
                    Api.model.update('taker',params,row.id).then(()=>{
                        this.success('成功')
                        this.getTakerList()
                    });
                })

            },
            deleteItem() {
                this.$confirm("确认删除此条目？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
        }
    }
</script>
