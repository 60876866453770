<template>
    <div class="content_container">
        <el-scrollbar>
            <div class="scroll-container">
                <div class="container" style="padding-top:0;padding-bottom:0;">
                    <h1 style="margin-top: 12px;font-weight: 600;font-size: 18px"  v-show="customer_id">{{customerData.name}}</h1>
                    <hr style="margin-top: 12px;background-color:#EBEEF5; height:1px; border:none;" v-show="customer_id">
                    <div class="header_container is-sticky-top">
                        <div class="left">
                            <el-button size="small" type="primary" @click="showProjectForm('projectData')" v-show="allow('project.store')"><i
                                    class="el-icon-plus"></i> 新建项目
                            </el-button>
                        </div>
                        <div class="right">
                            <div class="filter">
                                <el-form :inline="true" size="small">
                                    <el-form-item>
                                        <el-select v-model="filter.years_id" clearable filterable
                                                   placeholder="请选择年份">
                                            <el-option
                                                    v-for="item in filter.yearsOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="filter.customer_id" clearable filterable
                                                   placeholder="请选择企业">
                                            <el-option
                                                    v-for="item in customerOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="filter.manager_id" clearable filterable
                                                   placeholder="请选择负责人">
                                            <el-option
                                                    v-for="item in projectLeaderOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <el-form-item>
                                        <el-input
                                                placeholder="请输入关键字"
                                                v-model="filter.keyWord">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button size="small" @click="getProjectList"><i class="el-icon-search"></i>
                                        </el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="table-list">
                        <el-table
                                :data="tableData">
                            <el-table-column
                                    prop="id"
                                    :show-overflow-tooltip="true"
                                    width="55"
                                    align="center"
                                    label="ID"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :show-overflow-tooltip="true"
                                    label="项目名称"
                                    min-width="20%"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="code"
                                    :show-overflow-tooltip="true"
                                    label="项目编号"
                                    align="center"
                                    width="120"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="enterpriseTitle"
                                    :show-overflow-tooltip="true"
                                    label="企业名称"
                                    min-width="20%"
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.customer.name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="contact_people_name"
                                    :show-overflow-tooltip="true"
                                    label="联系人"
                                    align="center"
                                    min-width="15%"
                            ></el-table-column>
                            <el-table-column
                                    prop="contact_people_phone"
                                    :show-overflow-tooltip="true"
                                    label="联系电话"
                                    align="center"
                                    min-width="15%"
                            ></el-table-column>
                            <el-table-column
                                    prop="user.name"
                                    :show-overflow-tooltip="true"
                                    label="负责人"
                                    align="center"
                                    min-width="15%"
                            ></el-table-column>
                            <!-- <el-table-column
                                    prop="valided_at"
                                    :show-overflow-tooltip="true"
                                    label="立项时间"
                                    min-width="15%"
                                    align="center"
                            ></el-table-column> -->
                            <el-table-column
                                    prop="valided_at"
                                    :show-overflow-tooltip="true"
                                    label="有效期"
                                    min-width="20%"
                                    align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.valided_at}} ~ {{scope.row.expire_at}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="progress"
                                    width="80"
                                    align="center"
                                    label="进度"
                                    sortable
                            >
                                <template slot-scope="scope">
                                    <el-progress
                                            :stroke-width="2"
                                            :percentage="scope.row.final_task_children_count?Math.floor((scope.row.final_task_children_count/scope.row.task_children_count)*100):0"
                                            :color="customColorMethod"
                                            type="circle"
                                            :width="40"></el-progress>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="task_count"
                                    :show-overflow-tooltip="true"
                                    width="80"
                                    align="center"
                                    label="主任务"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="toTask(scope.row)">{{scope.row.task_count}}</el-link>
                                </template>
                            </el-table-column>
                            <!--<el-table-column-->
                                    <!--prop="status"-->
                                    <!--width="80"-->
                                    <!--:show-overflow-tooltip="true"-->
                                    <!--align="center"-->
                                    <!--label="状态"-->
                            <!--&gt;-->
                                <!--<template slot-scope="scope">-->
                                    <!--<el-tag size="small" type="warning" v-if="scope.row.status == 1">进行中</el-tag>-->
                                    <!--<el-tag size="small" type="success" v-else-if="scope.row.status == 2">已完成</el-tag>-->
                                    <!--<el-tag size="small" type="danger" v-else>已暂停</el-tag>-->
                                <!--</template>-->
                            <!--</el-table-column>-->
                            <el-table-column
                                    prop="agreement"
                                    width="65"
                                    align="center"
                                    label="合同"
                            >
                                <template slot-scope="scope">
                                    <el-link type="primary" @click="openWindowBlank(scope.row.agreement.file_path)" v-if="scope.row.agreement_id != null && scope.row.agreement!=null"><i
                                            class="el-icon-document"></i></el-link>
                                    <el-upload
                                            v-show="allow('project.update')"
                                            v-else
                                            class="uploadThumbImg"
                                            :action="uploadURL"
                                            :headers="uploadHeaders"
                                            :on-success="handleUploadSuccess"
                                            :data="{module:'project',model_id:scope.row.id}"
                                            :limit="1">
                                        <i class="el-icon-upload2"></i>
                                    </el-upload>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" width="160">
                                <template slot-scope="scope">
                                    <el-button type="text" title="对子公司价目表" @click="showCompanyPrice(scope.row)"><i
                                            class="el-icon-document"></i></el-button>
                                    <el-button type="text" title="对企价目表" @click="showQuotation(scope.row,2)"><i
                                            class="el-icon-document"></i></el-button>
                                    <el-button type="text" title="对医价目表" @click="showQuotation(scope.row,1)"><i
                                            class="el-icon-document"></i></el-button>
                                    <el-button type="text" title="查看" @click="showProjectView(scope.row.id)"><i
                                            class="el-icon-view"></i></el-button>
                                    <el-button type="text" title="编辑"
                                               v-show="allow('project.update')"
                                               @click="showProjectForm('projectData');getProjectInfo(scope.row.id);"><i
                                            class="el-icon-edit"></i></el-button>
                                    <!-- <el-button type="text" title="删除" @click="deleteItem"><i class="el-icon-delete"></i></el-button> -->
                                    <!--<el-button type="text"-->
                                               <!--v-show="allow('project.update')"-->
                                               <!--:title="[scope.row.status==3? '启用' : '暂停']"-->
                                               <!--@click="isOpen(scope.row)"-->
                                    <!--&gt;-->
                                        <!--<i class="el-icon-open" v-if="scope.row.status!=3"></i>-->
                                        <!--<i class="el-icon-turn-off" v-else></i>-->
                                    <!--</el-button>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="footer_container is-sticky-bottom">
                        <el-pagination
                                :current-page="currentPage"
                                :pager-count="5"
                                :page-size="pageSize"
                                layout="total, prev, pager, next"
                                :total="total"
                                @current-change="currentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <!-- 新建项目 -->
        <el-dialog
                custom-class="dialog_tc"
                :title="title"
                :visible.sync="addProgram"
                width="1000px"
        >
            <el-form ref="projectData" :rules="rules" :model="addData" label-width="80px" size="medium">
                <el-form-item label="项目名称" prop="name">
                    <el-input
                            type="text"
                            placeholder="请输入项目名称"
                            v-model="addData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业名称" prop="customer_id">
                    <el-select v-model="addData.customer_id" placeholder="请选择" filterable clearable @change="changeCustomer">
                        <el-option
                                v-for="item in customerOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目编号" prop="code">
                    <el-input
                            type="text"
                            placeholder="请输入项目编号"
                            v-model="addData.code"
                            disabled
                    ></el-input>
                </el-form-item>

                <el-form-item label="联系人" prop="contact_people_name">
                    <el-input
                            type="text"
                            placeholder="请输入项目联系人"
                            v-model="addData.contact_people_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" required prop="contact_people_phone">
                    <el-input
                            type="text"
                            placeholder="请输入联系电话"
                            v-model="addData.contact_people_phone"
                    ></el-input>
                </el-form-item>
                <el-form-item label="负责人" required prop="manager_id">
                    <el-select v-model="addData.manager_id" placeholder="请选择" filterable clearable>
                        <el-option
                                v-for="item in projectLeaderOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="有效期" required prop="timeRange">
                    <!-- <el-date-picker
                            v-model="addData.valided_at"
                            type="date"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择立项时间">
                    </el-date-picker> -->
                    <el-date-picker
                            v-model="addData.timeRange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            align="right"
                            @change="changeTime"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="合同"  prop="">
                    <el-upload
                            class="uploadThumbImg"
                            :action="uploadURL"
                            :headers="uploadHeaders"
                            :limit="1"
                            :data="{module:'project'}"
                            :on-success="handleUploadAddSuccess"
                            :file-list="addData.fileList">
                        <div class="flex">
                            <el-button size="medium" plain>点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="margin-left:8px;margin-top:0;">只支持上传pdf文件
                            </div>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="项目详情" prop="content">
                    <Complex v-model="addData.content"></Complex>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addProgram = false">取 消</el-button>
        <el-button size="small" v-if="addData.id" type="primary" @click="editProjectData('projectData')">保存</el-button>
        <el-button size="small" v-else type="primary" @click="saveProjectData('projectData')">保存</el-button>
      </span>
        </el-dialog>

        <!-- 项目详情 -->
        <el-drawer
                :visible.sync="programView"
                class="drawer_tc"
                size="40%"
                direction="rtl">
            <div class="drawer-title" slot="title">
                <div class="title">
                    {{viewData.name}}
                </div>
            </div>
            <div class="content_container">
                <el-scrollbar>
                    <div class="container">
                        <el-descriptions class="margin-top" :column="2" size="medium" border>
                            <el-descriptions-item>
                                <template slot="label">
                                    项目编号
                                </template>
                                {{viewData.code}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    企业名称
                                </template>
                                {{viewData.customer!=undefined?viewData.customer.name:''}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    联系人
                                </template>
                                {{viewData.contact_people_name}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    联系电话
                                </template>
                                {{viewData.contact_people_phone}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    负责人
                                </template>
                                {{viewData.user!=undefined?viewData.user.name:''}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    有效期
                                </template>
                                {{viewData.valided_at}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    项目进度
                                </template>
                                <el-progress
                                        :stroke-width="8"
                                        :percentage="viewData.final_task_children_count?Math.floor((viewData.final_task_children_count/viewData.task_children_count)*100):0"
                                        :color="customColorMethod"></el-progress>
                            </el-descriptions-item>
                            <!--<el-descriptions-item>-->
                                <!--<template slot="label">-->
                                    <!--项目状态-->
                                <!--</template>-->
                                <!--<el-tag size="small" v-if="viewData.status==1" type="warning">进行中</el-tag>-->
                                <!--<el-tag size="small" v-else-if="viewData.status==2" type="warning">已完成</el-tag>-->
                                <!--<el-tag size="small" v-else-if="viewData.status==3" type="warning">已暂停</el-tag>-->
                            <!--</el-descriptions-item>-->
                            <el-descriptions-item>
                                <template slot="label" >
                                    任务数
                                </template>

                                <el-link type="primary" @click="toTask(viewData)">{{viewData.task_count}}</el-link>


                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    合同
                                </template>
                                <el-link type="primary" @click="viewData.agreement!=null?openWindowBlank(viewData.agreement.file_path):''">点击查看</el-link>
                                <!-- <el-upload
                                  v-else
                                  class="uploadThumbImg"
                                  action="https://jsonplaceholder.typicode.com/posts/"
                                  :limit="1">
                                  点击上传
                                </el-upload> -->
                            </el-descriptions-item>
                            <el-descriptions-item :labelStyle="{'vertical-align': 'top'}">
                                <template slot="label">
                                    项目详情
                                </template>
                                <span v-html="viewData.content"></span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-scrollbar>
            </div>
        </el-drawer>

        <!-- 对价表详情 -->
        <Price :id="id"
               :drawTitle="drawTitle"
               :type="type"
               :quotationView="quotationView"
               :viewData="viewData"
               :key="key"
               @closeDrawer="closeD"
        ></Price>
        <CompanyPrice
                :id="id"
                :company_price_key="company_price_key"
                :companyPriceView="companyPriceView"
                @closeDrawer="closeCompanyPriceShow"
        >
        </CompanyPrice>
    </div>
</template>
<script>
    import mixinPage from '@/mixins/page'
    import Editor from "../../components/tinymce-editor/tinymce-editor";
    import Complex from "../../components/tinymce-editor/tinymce-complex";
    import Price from "../../components/price/price"
    import CompanyPrice from "../../components/price/Companyprice"
    import Api from '@/api'

    export default {
        mixins: [mixinPage],
        components: {
            Editor,
            Complex,
            Price,
            CompanyPrice
        },
        data() {
            return {
                company_price_key:0,
                customer_id:'',
                customerData:{
                    name:''
                },
                drawTitle:'',
                key:0,
                id: 0,
                type:0,
                title: '新建项目',
                currentPage: 1,
                pageSize: 15,
                total: 0,
                customerOptions: [],
                projectLeaderOptions: [],
                filter: {
                    keyWord: '',
                    customer_id: '',
                    manager_id: "",
                    years_id: '',
                    yearsOptions: [{
                        value: '2022',
                        label: '2022'
                    }, {
                        value: '2021',
                        label: '2021'
                    }, {
                        value: '2020',
                        label: '2020'
                    }, {
                        value: '2019',
                        label: '2019'
                    }, {
                        value: '2018',
                        label: '2018'
                    }]
                },
                tableData: [],
                addProgram: false,
                addData: {
                    id: '',
                    name: '',
                    code: '',
                    customer_id: '',
                    contact_people_name: '',
                    contact_people_phone: '',
                    manager_id: '',
                    timeRange:[],
                    valided_at: '',
                    expire_at:'',
                    content: '',
                    file_info:{},
                    fileList:[]
                },
                viewData: {},
                programView: false,
                rules: {
                    name: [
                        {
                            required: true,
                            message: '请输入项目名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    code: [
                        {
                            required: true,
                            message: '请输入项目代号',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    customer_id: [
                        {
                            required: true,
                            message: '请选着企业名称',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    contact_people_name: [
                        {
                            required: true,
                            message: '请输入联系人',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    contact_people_phone: [
                        {
                            required: true,
                            message: '请输入联系手机号',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    manager_id: [
                        {
                            required: true,
                            message: '请选择负责人',
                            trigger: ['change', 'blur'],
                        },
                    ],
                    timeRange: [
                        {
                            required: true,
                            message: '请选择立项时间',
                            trigger: ['change', 'blur'],
                        },
                    ],
                },
                quotationView: false, //价目表
                companyPriceView:false,//对子公司价目表
                quotationEditable: false,
            }
        },
        $route: {
            handler: function(val, oldVal){
                this.customer_id = val.query.customer_id==undefined?'':val.query.customer_id
                this.getCustomerList()
            }
        },
        created() {
            this.customer_id = this.$route.query.customer_id
            this.addData.customer_id = this.$route.query.customer_id?parseInt( this.$route.query.customer_id):''
            //获取企业列表
            this.getCustomerList()
            //获取负责人列表
            this.getUserList()
        },
        mounted() {
            this.getProjectList();
        },
        methods: {
            changeCustomer(value){

                let temp = this.customerOptions.filter(item=>{
                    return item.value == value
                })
                let code = temp[0].code
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                }
                this.addData.code = 'JK-QF-'+code+'-'+date.year + date.month

            },
            //文件上传成功后操作
            async handleUploadSuccess(response){
                if(response.code == 200){
                    let params = {
                        model_id:response.data.model_id,
                        module:response.data.module,
                        file_path:response.data.uri
                    }
                    //修改
                    await Api.project.saveAgreement(params).then(res=>{
                        this.getProjectList();
                    })
                }
            },
            async handleUploadAddSuccess(response){
                if(response.code == 200){
                    this.addData.file_info = {
                        model_id:response.data.model_id,
                        module:response.data.module,
                        file_path:response.data.uri
                    }
                }
            },
            changeTime(e){

                this.addData.valided_at = e[0]
                this.addData.expire_at = e[1]

            },
            closeD(){
                this.key+=1
                this.quotationView = false;
                this.id = 0
                this.type = 0
            },
            closeCompanyPriceShow(){
                this.company_price_key+=1
                this.companyPriceView = false;
                this.id = 0
            },
            showQuotation(row, type) {
                if(type==1){
                    this.drawTitle='对医价格表 - ' + row.name
                }else{
                    this.drawTitle='对企价格表 - ' + row.name
                }
                this.quotationView = true;
                this.id = row.id
                this.type = type

            },
            //对子公司价格表
            showCompanyPrice(row){
                this.id = row.id
                this.companyPriceView = true;
            },
            showProjectView(id) {
                this.programView = true
                this.getProjectInfo(id)
            },
            //获取单条项目详情
            async getProjectInfo(id) {
                this.title = '编辑项目'
                await Api.project.show(id).then((res) => {

                    this.$nextTick(() => {
                        Object.keys(this.addData).forEach(item=>{
                            if(res[item]!=undefined){
                                this.addData[item] = res[item]
                            }
                        })
                        let timeRange = [res.valided_at,res.expire_at]
                        this.addData.timeRange = timeRange
                        let fileList = []
                        if(res.agreement){
                            fileList.push({
                                name:res.agreement.name,
                                url:this.getImgUrl()+res.agreement.file_path
                            })
                            this.addData.file_info = {
                                model_id:res.id,
                                module:'project',
                                file_path:res.agreement.file_path
                            }
                        }

                        this.addData.fileList = fileList

                        this.viewData = res
                    })

                })
            },
            //显示
            showProjectForm(form) {
                this.addProgram = true
                this.addData.id = ""
                this.addData.timeRange = []
                this.addData.file_info = {}
                this.addData.fileList = []
                // console.log(form)
                this.resetForm(form)
            },
            //获取负责人列表
            async getUserList() {
                await Api.user.index().then((res) => {
                    let projectLeaderOptions = []
                    res.forEach((item) => {
                        projectLeaderOptions.push({
                            label: item.name,
                            value: item.id
                        })
                    })
                    this.projectLeaderOptions = projectLeaderOptions
                })
            },
            //获取企业名称
            async getCustomerList() {
                let params = {
                    limit: 'all'
                }
                await Api.customer.index(params).then((res) => {
                    let customerOptions = []
                    res.forEach((item) => {
                        customerOptions.push({
                            label: item.name,
                            value: item.id,
                            code:item.code
                        })
                    })
                    this.customerOptions = customerOptions
                })
            },
            //保存项目信息
            async saveProjectData(form) {
                let params = this.addData
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        await Api.project.store(params).then( async (res) => {
                            let data = this.addData.file_info
                            console.log(this.addData)
                            if(this.addData.file_info.file_path!=undefined){
                                data.model_id = res.id
                                await Api.project.saveAgreement(data).then(()=>{
                                    this.success('成功')
                                    this.addProgram = false
                                    this.resetForm(form)
                                    this.getProjectList()
                                })
                            }else{
                                this.success('成功')
                                this.addProgram = false
                                this.resetForm(form)
                                this.getProjectList()
                            }


                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                });

            },
            //修改企业信息
            async editProjectData(form) {

                let params = this.addData
                //验证必填项不能为空
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                        await Api.project.update(params).then(async (res) => {
                            let data = this.addData.file_info
                            data.model_id = res.id
                            if(this.addData.file_info.file_path!=undefined){
                                await Api.project.saveAgreement(data).then(()=>{
                                    this.success('成功')
                                    this.addProgram = false
                                    this.resetForm(form)
                                    this.getProjectList()
                                })
                            }


                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                });

            },
            //改变当前页码
            currentChange(currentPage) {
                this.currentPage = currentPage
                this.getProjectList()
            },
            //项目列表
            async getProjectList() {
                let params = {
                    year:this.filter.years_id,
                    page:this.currentPage,
                    pageSize: this.pageSize,
                    customer_id:  this.customer_id?this.customer_id:this.filter.customer_id,
                    manager_id: this.filter.manager_id,
                    keyWord: this.filter.keyWord,
                }
                await Api.project.index(params).then((res) => {
                    this.total = res.total
                    this.tableData = res.data
                    this.customerData = res.customer
                })
            },
            toTask(item) {
                this.$router.push({path:'/task',query:{project_id:item.id}});
            },
            customColorMethod(percentage) {
                if (percentage < 30) {
                    return '#909399';
                } else if (percentage < 70) {
                    return '#e6a23c';
                } else {
                    return '#67c23a';
                }
            },
            isOpen(row) {
                this.confirm(`确认${row.status != 3 ? '暂停' : '启用'}该项目？`,async () => {
                    let params = {project_id:row.id}
                    params.status= row.status == 3?'open':'close'
                    await Api.project.setStatus(params).then(()=>{
                        this.success()
                        this.getProjectList()
                    })

                })

            },
            deleteItem() {
                this.$confirm("确认删除此条目？", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
        }
    }
</script>
